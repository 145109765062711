import http from 'http/interceptor';
import { getItemFromStore } from 'helpers/utils';
import { HubConnectionBuilder } from '@microsoft/signalr';
const currentLocale =
  getItemFromStore('preferredLanguage', 'en') ||
  process.env.REACT_APP_DEFAULT_LANGUAGE;
const BASE_API_URL = `${process.env.REACT_APP_API_BASE_URL}/api/${currentLocale}/${process.env.REACT_APP_API_VERSION}`;
const NOTIFICATIONS_HUB_URL = `${process.env.REACT_APP_API_BASE_URL}/api/n`;

const TEST_NOTIFICATION_URL = `${BASE_API_URL}/account/sendTestNotification`;
const LIST_NOTIFICATIONS_URL = `${BASE_API_URL}/notifications/list`;
const MARK_ALL_AS_READ_URL = `${BASE_API_URL}/notifications/UpdateStatus`;
const MARK_AS_READ_URL = `${BASE_API_URL}/notifications/UpdateStatus`;

const NotificationsService = {
  initiateConnection: async (
    user,
    token,
    connectionCallBack,
    onNewNotificationCallBack
  ) => {
    let connect = new HubConnectionBuilder()
      .withUrl(NOTIFICATIONS_HUB_URL, {
        accessTokenFactory: () => token
      })
      .withAutomaticReconnect()
      .build();

    await connect.start();
    //console.log('CONNECTION INITIATED');
    connectionCallBack && connectionCallBack(connect);
    connect.on('SendToRole', message => {
      if (!user) {
        return;
      }
      let userRoles = user?.roles;
      if (typeof userRoles === 'string') {
        userRoles = [userRoles];
      }
      if (userRoles.includes(message)) {
        onNewNotificationCallBack && onNewNotificationCallBack(message);
      }
    });
  },
  sendTestNotifications: data => {
    return http.post(`${TEST_NOTIFICATION_URL}?content=${data}`, {
      headers: { 'Content-Type': 'multipart/form-data' }
    });
  },
  list: data => {
    return http.post(`${LIST_NOTIFICATIONS_URL}`, data, {
      headers: { 'Content-Type': 'Application/Json' }
    });
  },
  markAllAsRead: () => {
    return http.post(
      `${MARK_ALL_AS_READ_URL}`,
      { readAll: true },
      {
        headers: { 'Content-Type': 'Application/Json' }
      }
    );
  },
  markAsRead: data => {
    return http.post(`${MARK_AS_READ_URL}`, data, {
      headers: { 'Content-Type': 'Application/Json' }
    });
  }
};

export default NotificationsService;
