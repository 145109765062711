import http from 'http/interceptor';
import { getItemFromStore } from 'helpers/utils';
const currentLocale =
  getItemFromStore('preferredLanguage', 'en') ||
  process.env.REACT_APP_DEFAULT_LANGUAGE;
const BASE_API_URL = `${process.env.REACT_APP_API_BASE_URL}/api/${currentLocale}/${process.env.REACT_APP_API_VERSION}`;
const LIST_STUDENTS_RL = `${BASE_API_URL}/users/students/list`;

const StudentsService = {
  list: payload => {
    //const url = `${LIST_USER_RL}?${objectToQueryString(payload)}`;
    const url = `${LIST_STUDENTS_RL}`;

    return http.post(url, payload, {
      headers: { 'Content-Type': 'Application/Json' }
    });
  }
};

export default StudentsService;
