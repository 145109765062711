import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import defaultCoverSrc from 'assets/img/shai/bg-banner.png';
import defaultAvatar from 'assets/img/team/avatar.png';
import Flex from 'components/common/Flex';
import VerifiedBadge from 'components/common/VerifiedBadge';
import React from 'react';
import { Badge, Col, Row } from 'react-bootstrap';
import ProfileBanner from '../settings/ProfileBanner';
import { faEnvelope, faLock, faPhone } from '@fortawesome/free-solid-svg-icons';
import { toApiFileUrl, truncateString } from 'helpers/utils';

const Banner = ({ user, t }) => {
  const rightSidedItems = [
    {
      title: t('user:labels.email'),
      image: faEnvelope,
      value: truncateString(user?.email || user?.emailAddress, 40),
      type: 'email'
    },
    {
      title: t('user:labels.phoneNumber'),
      image: faPhone,
      value: user?.phoneNumber || ''
    },
    {
      title: t('user:labels.roles'),
      image: faLock,
      value: user?.roleNames?.map(role => role?.name),
      type: 'badge'
    }
  ];
  return (
    <ProfileBanner>
      <ProfileBanner.Header
        avatar={user?.avatarUrl ? toApiFileUrl(user?.avatarUrl) : defaultAvatar}
        coverSrc={user?.coverUrl || defaultCoverSrc}
      />
      <ProfileBanner.Body>
        <Row>
          <Col lg={8}>
            <h4 className="mb-1">
              {user?.fullName || 'Unknown'} <VerifiedBadge />
            </h4>
            <h5 className="fs-0 fw-normal">{user?.subtitle || ''}</h5>
            <p className="text-500">{`${user?.country || ''} ${
              user?.city ? `/ ${user?.city}` : ''
            }`}</p>
            <div className="border-dashed border-bottom my-4 d-lg-none" />
          </Col>
          <Col className="ps-2 ps-lg-3">
            {rightSidedItems.map(item => (
              <Flex alignItems="center" className="mb-2" key={item.title}>
                <FontAwesomeIcon icon={item.image} className="mx-2" />
                <div className="flex-1">
                  {item.type === 'email' ? (
                    <h6 className="mb-0">
                      {`${item.title} : `}
                      <span>
                        <a href={`mailto:${item.value}`}>{`${item.value}`}</a>
                      </span>
                    </h6>
                  ) : item.type === 'badge' ? (
                    <h6 className="mb-0">
                      <span>
                        {`${item.title} : `}
                        <span>
                          {item.value?.map(role => {
                            return (
                              <Badge
                                key={role}
                                color="soft-info"
                                className="mx-1"
                              >
                                {t(`common:roles.${role.toLowerCase().trim()}`)}
                              </Badge>
                            );
                          })}
                        </span>
                      </span>
                    </h6>
                  ) : (
                    <h6 className="mb-0">{`${item.title} : ${item.value}`}</h6>
                  )}
                </div>
              </Flex>
            ))}
          </Col>
        </Row>
      </ProfileBanner.Body>
    </ProfileBanner>
  );
};

export default Banner;
