import React, { useState, useContext, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Editor } from '@tinymce/tinymce-react';
import AppContext from 'context/Context';
import { generateRandomPassword, getColor } from 'helpers/utils';
import { useTranslation } from 'react-i18next';

const TinymceEditor = ({
  value,
  defaultValue,
  handleChange,
  disabled,
  height = '50vh',
  invalid,
  enableFullscreen,
  ...rest
}) => {
  const {
    config: { isDark, isRTL }
  } = useContext(AppContext);
  const editorRef = useRef(null);
  const { t } = useTranslation();
  const [customClass, setCustomClass] = useState(undefined);
  const [editorKey, setEditorKey] = useState(generateRandomPassword(8));
  useEffect(() => {
    if (editorRef.current) {
      editorRef.current.dom.addStyle(
        `body{color: ${getColor('white')} !important;}`
      );
    }
  }, [isDark]);

  const handleFullScreenCustomButtonClick = () => {
    //console.log('handleFullScreenCustomButtonClick', { customClass });
    if (!!customClass) {
      // document
      //   .getElementsByClassName('tox-tinymce')?.[0]
      //   ?.classList.remove('full-screen-editor');
      setEditorKey(generateRandomPassword(8));
      setCustomClass(undefined);
    } else {
      // document
      //   .getElementsByClassName('tox-tinymce')?.[0]
      //   ?.classList.add('full-screen-editor');
      setEditorKey(generateRandomPassword(8));
      setCustomClass('full-screen-editor');
    }
  };

  return (
    <div className={customClass}>
      <Editor
        key={editorKey}
        onInit={(evt, editor) => (editorRef.current = editor)}
        value={value}
        defaultValue={defaultValue}
        onEditorChange={handleChange}
        apiKey={process.env.REACT_APP_TINYMCE_APIKEY}
        disabled={disabled}
        config={{
          invalid
        }}
        init={{
          height,
          invalid,
          menubar: true,
          readonly: disabled ? 1 : 0,
          content_style: `body { color: ${getColor('black')} }`,
          menu: {
            view: {
              title: t('common:labels.view'),
              items:
                'code | visualaid visualchars visualblocks | spellchecker | preview fullscreen '
            },
            file: {
              title: t('common:labels.file'),
              items: 'newdocument restoredraft | preview | print '
            },
            edit: {
              title: t('common:labels.edit'),
              items: 'undo redo | cut copy paste | selectall | searchreplace'
            },
            insert: {
              title: t('common:labels.insert'),
              items:
                'image link media template codesample inserttable | charmap emoticons hr | pagebreak nonbreaking anchor toc | insertdatetime'
            },
            format: {
              title: t('common:labels.format'),
              items:
                'bold italic underline strikethrough superscript subscript codeformat | formats blockformats fontformats fontsizes align lineheight | forecolor backcolor | removeformat'
            },
            tools: {
              title: t('common:labels.tools'),
              items: 'spellchecker spellcheckerlanguage | code wordcount'
            },
            table: {
              title: t('common:labels.table'),
              items: 'inserttable | cell row column | tableprops deletetable'
            },
            help: { title: t('common:labels.help'), items: 'help' },
            fullScreen: {
              title: 'Full Screen',
              items: 'fullscreen',
              className: 'cursor-pointer'
            }
          },
          statusbar: true,
          plugins: 'link image lists table media directionality',
          // toolbar:
          //   'styleselect | bold italic link bullist numlist image blockquote table media undo redo',
          toolbar: `insertfile a11ycheck undo redo | bold italic | forecolor backcolor | template codesample | alignleft aligncenter alignright alignjustify | bullist numlist | link image | image | ${
            enableFullscreen ? 'customFullscreenBtn' : ''
          }`,

          directionality: isRTL ? 'rtl' : 'ltr',
          theme_advanced_toolbar_align: 'center',
          // Other configurations...
          setup: editor => {
            if (enableFullscreen) {
              editor.ui.registry.addButton('customFullscreenBtn', {
                tooltip: !!customClass
                  ? t('common:labels.exitFullscreen')
                  : t('common:labels.fullScreen'),
                icon: 'fullscreen',
                className: 'cursor-pointer',
                onAction: handleFullScreenCustomButtonClick
              });
            }
          }
        }}
        {...rest}
      />
    </div>
  );
};

TinymceEditor.propTypes = {
  value: PropTypes.string,
  handleChange: PropTypes.func,
  height: PropTypes.string
};

export default TinymceEditor;
