import React from 'react';
import ExamBasicForm from './components/ExamBasicForm';
import ExamQuestions from './components/ExamQuestions';

const CreateExam = () => {
  return (
    <>
      <ExamBasicForm />
      <ExamQuestions />
    </>
  );
};
export default CreateExam;
