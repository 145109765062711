import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useState } from 'react';
import { Form, InputGroup, OverlayTrigger, Tooltip } from 'react-bootstrap';
import MultiLanguageRTE from 'components/common/MultiLanguageRTE';

const QuestionAnswer = ({
  t,
  answer,
  index,
  currentLanguage,
  errors,
  onChange,
  onFocus,
  onRemove,
  correctDisabled
}) => {
  const [hovered, setHovered] = useState(false);
  return (
    <InputGroup
      className="mb-3 align-items-start  flex-md-nowrap"
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      <div>
        <OverlayTrigger
          overlay={
            <Tooltip id={`question-answers-remove-toolip-${index}`}>
              {correctDisabled
                ? t('exams:message.singleCorrectAnswerAllowed')
                : t('exams:labels.setAsCorrect')}
            </Tooltip>
          }
          className="mx-2"
        >
          <span style={{ height: '1.5rem' }} className="mx-2">
            <Form.Check
              type="switch"
              className="mt-2"
              id={`question-answer-${index}`}
              checked={answer?.correct || false}
              disabled={correctDisabled}
              onChange={e => {
                onChange(
                  {
                    ...answer,
                    correct: e.target.checked
                  },
                  index
                );
              }}
            />
          </span>
        </OverlayTrigger>
        {hovered && (
          <OverlayTrigger
            overlay={
              <Tooltip id={`question-answers-remove-toolip-${index}`}>
                {t('common:button.remove')}
              </Tooltip>
            }
            className="mx-2"
          >
            <span style={{ height: '1.5rem' }} className="mx-1 text-danger">
              <FontAwesomeIcon
                icon={faTrash}
                onClick={() => onRemove(index)}
                className="cursor-pointer"
              />
            </span>
          </OverlayTrigger>
        )}
      </div>
      <MultiLanguageRTE
        t={t}
        enableFullscreen
        containerClassName="w-100"
        activeLanguage={currentLanguage}
        valueEn={answer?.content?.en || ''}
        valueAr={answer?.content?.ar || ''}
        errorEn={!!errors?.contentEn?.error}
        errorAr={!!errors?.contentAr?.error}
        placeholderEn={t('course:placeholders.addNewItem')}
        placeholderAr={t('course:placeholders.addNewItem')}
        arEditorProps={{ onFocusIn: () => onFocus() }}
        enEditorProps={{ onFocusIn: () => onFocus() }}
        handleEnChange={newValue =>
          onChange(
            {
              ...answer,
              content: {
                ...answer?.content,
                en: newValue
              }
            },
            index
          )
        }
        handleArChange={newValue =>
          onChange(
            {
              ...answer,
              content: {
                ...answer?.content,
                ar: newValue
              }
            },
            index
          )
        }
      />
    </InputGroup>
  );
};

export default QuestionAnswer;
