import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from 'react-query';
import ErrorPlaceholder from 'components/common/ErrorPlaceholder';
import {
  Card,
  Col,
  OverlayTrigger,
  Placeholder,
  Row,
  Tooltip
} from 'react-bootstrap';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTablePagination from 'components/common/advance-table/AdvanceTablePagination';
import Flex from 'components/common/Flex';
import { toast } from 'react-toastify';
import moment from 'moment';
import { faEdit, faEye, faTrash } from '@fortawesome/free-solid-svg-icons';
import ConfirmModal from 'components/common/ConfirmModal';
import { getErrorMessage } from 'http/utils';
import { truncateString } from 'helpers/utils';
import StaticPagesService from 'http/StaticPagesService';
import StaticPagesListTableHeader from './components/StaticPagesListTableHeader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNavigate } from 'react-router-dom';

const defaultPaging = {
  pageNumber: 1,
  pageSize: 10
};

const defaultSorting = {
  sortBy: 'metadata.createdAt',
  sortDirection: 'DESC'
};

const StaticPagesList = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const isRtl = i18n.language === 'ar';
  const currentLanguage = i18n.language;
  const [staticPages, setStaticPages] = useState([]);
  const [pagingInfo, setPagingInfo] = useState(null);
  const [sortInfo, setSortInfo] = useState({ ...defaultSorting });
  const [filters, setFilters] = useState({
    ...defaultPaging,
    ...defaultSorting
  });
  const [confirmModalProps, setConfirmModalProps] = useState(null);

  const {
    isLoading: staticPagesLoading,
    error: staticPagesError,
    data: staticPagesData,
    refetch: fetchStaticPages
  } = useQuery(
    ['StaticPagesList', { ...filters, ...sortInfo }],
    () => StaticPagesService.list({ ...filters, ...sortInfo }),
    {
      retry: false,
      manual: true, // Prevents automatic refetching
      refetchOnWindowFocus: false,
      enabled: false
    }
  );

  const {
    mutate: deleteStaticPage,
    error: deleteStaticPageError,
    isLoading: deleteStaticPageLoading
  } = useMutation({
    mutationFn: StaticPagesService.delete,
    onSuccess: data => onDeleteStaticPageSuccess(data)
  });

  const onDeleteStaticPageSuccess = () => {
    fetchStaticPages({ ...filters, ...sortInfo, ...defaultPaging });
    setConfirmModalProps(null);
    toast.success(t(`common:message.deleteSuccess`), { theme: 'colored' });
  };

  useEffect(() => {
    fetchStaticPages({ ...filters, ...sortInfo, ...defaultPaging });
  }, [filters, sortInfo]);

  useEffect(() => {
    if (staticPagesData && !staticPagesLoading && !staticPagesError) {
      setStaticPages(staticPagesData?.payload?.data || []);
      setPagingInfo(staticPagesData?.payload?.pagingInfo);
    }
    if (staticPagesError) {
      toast.error(getErrorMessage(t, staticPagesError), {
        theme: 'colored',
        autoClose: false
      });
    }
  }, [staticPagesLoading, staticPagesError, staticPagesData]);

  const handleSort = column => {
    const direction = sortInfo.sortDirection === 'ASC' ? 'DESC' : 'ASC';
    setSortInfo({
      sortBy: column,
      sortDirection: direction
    });
  };

  const handleDelete = id => {
    setConfirmModalProps({
      isOpen: true,
      onClose: () => setConfirmModalProps(null),
      onConfirm: () => deleteStaticPage(id),
      message: t('staticPages:message.deletePageConfirm'),
      header: t('staticPages:message.deletePageTitle'),
      actionButtonColor: 'danger',
      actionButtonText: t('common:button.delete')
    });
  };

  const columns = [
    {
      accessor: 'title',
      Header: t('staticPages:labels.title'),
      disableSortBy: true,
      headerProps: {
        className: 'px-3',
        style: { height: '46px' }
        // onClick: () => handleSort(`title.${currentLanguage}`)
      },
      cellProps: {
        className: 'py-2 white-space-nowrap px-3 pe-xxl-4'
      },
      Cell: rowData => {
        const { title } = rowData.row.original;
        return (
          <p className="fw-semi-bold m-0 mt-2 mb-0">
            {title?.[currentLanguage]}
          </p>
        );
      }
    },
    {
      accessor: 'keywords',
      Header: t('staticPages:labels.keywords'),
      disableSortBy: true,
      headerProps: {
        style: {
          minWidth: '14.625rem'
        }
        //onClick: () => handleSort('keywords')
      },
      cellProps: {
        className: 'py-2 pe-4'
      },
      Cell: rowData => {
        const { keywords } = rowData.row.original;
        return (
          <p className=" break-word m-0 mt-2 mb-0">
            {keywords?.[currentLanguage]}
          </p>
        );
      }
    },
    {
      accessor: 'description',
      Header: t('staticPages:labels.description'),
      disableSortBy: true,
      headerProps: {
        // onClick: () => handleSort('description')
      },
      cellProps: {
        className: 'fs-0 '
      },
      Cell: rowData => {
        const { description } = rowData.row.original;
        return (
          <p className=" break-word m-0 mt-2 mb-0">
            {`${truncateString(description?.[currentLanguage], 35)}`}...
          </p>
        );
      }
    },
    {
      accessor: 'metadata.modifiedAt',
      Header: t('staticPages:labels.modifiedAt'),
      disableSortBy: true,
      headerProps: {
        className: 'text-start'
        //onClick: () => handleSort('metadata.modifiedAt')
      },
      Cell: rowData => {
        const { metadata } = rowData.row.original;
        return (
          <p className=" mb-0 mt-2">
            {metadata?.modifiedAt
              ? moment(metadata?.modifiedAt || new Date())
                  .locale(currentLanguage)
                  .format('YYYY-MM-DD hh:mm A')
              : t('common:labels.noDataAvailable')}
          </p>
        );
      }
    },
    {
      accessor: 'action',
      Header: '',
      headerProps: { className: 'text-start' },
      disableSortBy: true,
      exportable: false,
      Cell: rowData => {
        const { id } = rowData.row.original;
        return (
          <Flex justifyContent="center" alignItems="center" gap="2">
            <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip id={`staticPage-tooltip-edit`}>
                  {t('common:button.edit')}
                </Tooltip>
              }
            >
              <span className="ms-2 text-primary fs-0 cursor-pointer">
                <FontAwesomeIcon
                  icon={faEdit}
                  onClick={() => navigate(`/contents/pages/create/${id}`)}
                />
              </span>
            </OverlayTrigger>
            <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip id={`staticPage-tooltip-preview`}>
                  {t('common:button.preview')}
                </Tooltip>
              }
            >
              <span className="ms-2 text-primary fs-0 cursor-pointer">
                <FontAwesomeIcon
                  icon={faEye}
                  onClick={() => navigate(`/contents/pages/preview/${id}`)}
                />
              </span>
            </OverlayTrigger>
            <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip id={`staticPage-tooltip-delete`}>
                  {t('common:button.delete')}
                </Tooltip>
              }
            >
              <span className="ms-2 text-primary fs-0 cursor-pointer">
                <FontAwesomeIcon
                  icon={faTrash}
                  onClick={() => handleDelete(id)}
                />
              </span>
            </OverlayTrigger>
          </Flex>
        );
      }
    }
  ];

  useEffect(() => {
    if (deleteStaticPageError) {
      toast.error(getErrorMessage(t, deleteStaticPageError), {
        theme: 'colored',
        autoClose: false
      });
    }
  }, [deleteStaticPageError]);

  if (staticPagesError) {
    return (
      <ErrorPlaceholder
        message={t('staticPages:labels.errorLoadingStaticPages')}
        className="mt-3"
      />
    );
  }

  return (
    <Row className="gx-3">
      <Col>
        <Card>
          <Card.Header className="border-bottom border-200 px-0">
            <StaticPagesListTableHeader
              table
              t={t}
              filters={filters}
              setFilters={setFilters}
            />
          </Card.Header>
          <Card.Body className={staticPagesLoading ? '' : 'p-0'}>
            {staticPagesLoading &&
              Array(14)
                .fill(1)
                .map((n, i) => {
                  return (
                    <Placeholder
                      key={`staticPages-table-skeleton-${i}`}
                      className="w-100 my-1"
                      style={{ height: '33px' }}
                      size="lg"
                    />
                  );
                })}
            {!staticPagesLoading && (
              <AdvanceTableWrapper
                columns={columns}
                data={staticPages || []}
                sortable
                pagination
                perPage={10}
                rowCount={staticPages?.length || 0}
                manualSortBy
              >
                <AdvanceTable
                  table
                  headerClassName="bg-light text-800 align-middle"
                  rowClassName="btn-reveal-trigger align-top"
                  tableProps={{
                    size: 'sm',
                    className: 'fs--1 mb-0',
                    style: { minHeight: '200px' }
                  }}
                />
              </AdvanceTableWrapper>
            )}
            {!staticPagesLoading && !staticPages?.length && (
              <p className="w-100 text-center">
                {t('common:placeholder.noDataAvailable')}
              </p>
            )}
          </Card.Body>
          <Card.Footer>
            <AdvanceTablePagination
              table
              pageCount={pagingInfo?.totalPages || 0}
              pageIndex={pagingInfo?.currentPage || 1}
              gotoPage={page => {
                setFilters({ ...filters, pageNumber: page });
              }}
              isRtl={isRtl}
            />
          </Card.Footer>
        </Card>
      </Col>
      {!!confirmModalProps && (
        <ConfirmModal
          {...confirmModalProps}
          loading={deleteStaticPageLoading}
        />
      )}
    </Row>
  );
};

export default StaticPagesList;
