/* eslint-disable react/prop-types */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import React from 'react';
import { Button } from 'react-bootstrap';
import Flex from '../Flex';

export const AdvanceTablePagination = ({
  pageCount,
  pageIndex,
  gotoPage,
  isRtl
}) => {
  const getPageRange = () => {
    const pageRange = [];
    for (
      let i = Math.max(1, pageIndex - 2);
      i <= Math.min(pageCount, pageIndex + 2);
      i++
    ) {
      pageRange.push(i);
    }
    return pageRange;
  };

  return (
    <Flex alignItems="center" justifyContent="center">
      <Button
        size="sm"
        variant="shai-default"
        onClick={() => gotoPage(pageIndex - 1)}
        className={classNames({ disabled: pageIndex <= 1 })}
      >
        <FontAwesomeIcon icon={`${isRtl ? 'chevron-right' : 'chevron-left'}`} />
      </Button>
      <ul className="pagination mb-0 mx-2">
        {pageIndex > 3 && (
          <>
            <li className={classNames({ active: pageIndex === 1 })}>
              <Button
                size="sm"
                variant="shai-default"
                className="page"
                onClick={() => gotoPage(1)}
              >
                1
              </Button>
            </li>
            <li>
              <span className="mx-2">...</span>
            </li>
          </>
        )}
        {getPageRange().map((page, index) => (
          <li key={page} className={classNames({ active: pageIndex === page })}>
            <Button
              size="sm"
              variant="shai-default"
              className={classNames('page', {
                'me-2': index !== pageCount
              })}
              onClick={() => gotoPage(page)}
            >
              {page}
            </Button>
          </li>
        ))}
        {pageIndex < pageCount - 4 && (
          <>
            <li>
              <span className="mx-2">...</span>
            </li>
            <li className={classNames({ active: pageIndex === pageCount })}>
              <Button
                size="sm"
                variant="shai-default"
                className={classNames('page', {
                  'me-2': pageIndex + 1 !== pageCount
                })}
                onClick={() => gotoPage(pageCount)}
              >
                {pageCount}
              </Button>
            </li>
          </>
        )}
      </ul>
      <Button
        size="sm"
        variant="shai-default"
        onClick={() => gotoPage(pageIndex + 1)}
        className={classNames({ disabled: pageIndex >= pageCount })}
      >
        <FontAwesomeIcon icon={`${isRtl ? 'chevron-left' : 'chevron-right'}`} />
      </Button>
    </Flex>
  );
};

export default AdvanceTablePagination;
