export const toExamFormSchema = exam => {
  if (!exam) {
    return null;
  }
  return {
    ...exam,
    examTitleAr: exam.title?.ar || '',
    examTitleEn: exam.title?.en || '',
    examDescriptionAr: exam.description?.ar || '',
    examDescriptionEn: exam.description?.en || '',
    tags: exam?.tags?.length
      ? [
          ...(exam?.tags?.map(tag => ({
            label: tag,
            value: tag,
            __isNew__: true
          })) || [])
        ]
      : []
  };
};

export const canEditExam = user => {
  if (!user || !user?.roles?.length) {
    console.log('user missing roles', user);
    return false;
  }
  let roles = user.roles;
  const allowedRoles = ['admin', 'planner'];
  if (typeof roles === 'string') {
    roles = [roles];
  }
  const canEdit = !!roles
    .map(r => r.toLowerCase())
    .some(r => allowedRoles.includes(r));
  // console.log(
  //   'canEditCourse',
  //   `${canEdit} user is ${roles.map(r => r).join(',')}`
  // );
  return canEdit;
};
