import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import Background from 'components/common/Background';
import corner6 from 'assets/img/illustrations/corner-6.png';
import {
  Button,
  Card,
  Col,
  Form,
  InputGroup,
  OverlayTrigger,
  Row,
  Tooltip
} from 'react-bootstrap';
import Flex from 'components/common/Flex';
import { useNavigate } from 'react-router-dom';
import debounce from 'lodash.debounce';
import { useState } from 'react';

const LearningPathListHeader = ({
  setShowFilterOffcanvas,
  filters,
  handleFilter,
  t
}) => {
  const navigate = useNavigate();
  const [selectedSortValue, setSelectedSortValue] = useState('settings.price');
  const debouncedHandleChange = debounce(value => {
    handleFilter({
      ...filters,
      query: {
        term: value,
        conjunction: 'or',
        properties: [
          'title.ar',
          'title.en',
          'subtitle.ar',
          'subtitle.en',
          'description.ar',
          'description.en'
        ]
      }
    });
  }, 500);

  return (
    <Card className="mb-3">
      <Card.Header className="position-relative">
        <Flex
          justifyContent="start"
          alignItems="center"
          gap="3"
          className="position-relative"
          style={{ zIndex: 2 }}
        >
          <h5 className="mb-0 mt-1">{t('learningPath:learningPathTitle')}</h5>

          <OverlayTrigger
            placement="top"
            overlay={
              <Tooltip id="user-newLearningPath-tooltip">
                {t('learningPath:button.newLearningPath')}
              </Tooltip>
            }
          >
            <span>
              <Button
                onClick={() => navigate('/learningPath/create')}
                size="sm"
              >
                {t('learningPath:button.newLearningPath')}
              </Button>
            </span>
          </OverlayTrigger>
        </Flex>
        <Background
          style={{ zIndex: 1 }}
          image={corner6}
          className="d-none d-md-block bg-card"
        />
      </Card.Header>
      <Card.Body className="pt-0 pt-md-3">
        <Row className="g-3 align-items-center">
          <Col xs="auto" className="d-xl-none">
            <Button
              onClick={() => setShowFilterOffcanvas(true)}
              className="position-relative p-0"
              size="sm"
              variant="link"
            >
              <FontAwesomeIcon icon="filter" className="fs-0 text-700" />
            </Button>
          </Col>
          <Col>
            <Form className="position-relative">
              <Form.Control
                type="search"
                placeholder={t('common:placeholder.search')}
                size="sm"
                aria-label="Search"
                className="rounded search-input ps-4"
                onChange={({ target }) => debouncedHandleChange(target.value)}
              />
              <FontAwesomeIcon
                icon="search"
                className="fs--1 text-400 position-absolute text-400 start-0 top-50 translate-middle-y ms-2"
              />
            </Form>
          </Col>
          <Col xs="auto" className="col-md">
            <Row className="g-0 justify-content-end">
              <Col
                xs="auto"
                className="position-md-static position-absolute top-0 end-0 me-3 mt-3 mt-md-0"
              >
                <Form as={Row} className="gx-2">
                  <Col xs="auto" className="d-none d-lg-block">
                    <small>{`${t('learningPath:labels.sortBy')} :`}</small>
                  </Col>
                  <Col xs="auto">
                    <InputGroup size="sm">
                      <Form.Select
                        className="pe-5"
                        defaultValue="price"
                        style={{ maxWidth: '6.7rem' }}
                        onChange={({ target }) => {
                          setSelectedSortValue(target.value);
                          handleFilter({ ...filters, sortBy: target.value });
                        }}
                      >
                        <option value="settings.price">
                          {t('learningPath:labels.price')}
                        </option>
                        <option value="stats.rate">
                          {t('learningPath:labels.rating')}
                        </option>
                        {/* <option value="review">
                          {t('learningPath:labels.review')}
                        </option> */}
                      </Form.Select>
                      <InputGroup.Text
                        as={Button}
                        variant="link"
                        className="border border-300 text-700"
                        onClick={() =>
                          handleFilter({
                            ...filters,
                            sortBy: selectedSortValue,
                            sortDirection:
                              filters?.sortDirection?.toLowerCase() === 'asc'
                                ? 'desc'
                                : 'asc'
                          })
                        }
                      >
                        <FontAwesomeIcon
                          icon={
                            filters?.sortDirection?.toLowerCase() === 'asc'
                              ? 'sort-amount-up'
                              : 'sort-amount-down'
                          }
                        />
                      </InputGroup.Text>
                    </InputGroup>
                  </Col>
                </Form>
              </Col>
            </Row>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

LearningPathListHeader.propTypes = {
  layout: PropTypes.string,
  isAsc: PropTypes.bool,
  setIsAsc: PropTypes.func,
  setSortBy: PropTypes.func,
  setShowFilterOffcanvas: PropTypes.func,
  searchedText: PropTypes.string,
  handleSearch: PropTypes.func
};

export default LearningPathListHeader;
