import React from 'react';
import { Card, Placeholder } from 'react-bootstrap';

import classNames from 'classnames';
import { useQuery } from 'react-query';
import { useState } from 'react';
import { useEffect } from 'react';
import { toast } from 'react-toastify';
import { getErrorMessage } from 'http/utils';
import { useTranslation } from 'react-i18next';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import { Link } from 'react-router-dom';
import AdvanceTablePagination from 'components/common/advance-table/AdvanceTablePagination';
import moment from 'moment';
import UserService from 'http/UserService';
import SoftBadge from 'components/common/SoftBadge';
import { toApiFileUrl, truncateString } from 'helpers/utils';
import Avatar from 'components/common/Avatar';
import Flex from 'components/common/Flex';

const defaultPaging = {
  pageNumber: 1,
  pageSize: 5
};

const defaultSorting = {
  sortBy: 'metadata.createdAt',
  sortDirection: 'DESC'
};

const NewUsers = ({ className }) => {
  const { t, i18n } = useTranslation();
  const isRtl = i18n.language === 'ar';
  const currentLanguage = i18n.language;
  const [users, setUsers] = useState([]);
  const [pagingInfo, setPagingInfo] = useState(null);
  const [sortInfo, setSortInfo] = useState({ ...defaultSorting });
  const [filters, setFilters] = useState({
    ...defaultPaging,
    ...defaultSorting
  });

  const {
    isLoading: usersLoading,
    error: usersError,
    data: usersData
  } = useQuery(
    ['NewUsersList', { ...filters, ...sortInfo }],
    () => UserService.list({ ...filters, ...sortInfo }),
    {
      retry: false,
      manual: true, // Prevents automatic refetching
      refetchOnWindowFocus: false,
      enabled: true
    }
  );

  useEffect(() => {
    if (usersData && !usersLoading && !usersError) {
      setUsers(usersData.data);
      setPagingInfo(usersData.pagingInfo);
    }
    if (usersError) {
      toast.error(getErrorMessage(t, usersError), {
        theme: 'colored',
        autoClose: false
      });
    }
  }, [usersLoading, usersError, usersData]);

  const handleSort = column => {
    const direction = sortInfo.sortDirection === 'ASC' ? 'DESC' : 'ASC';
    setSortInfo({
      sortBy: column,
      sortDirection: direction
    });
  };

  const columns = [
    {
      accessor: 'fullName',
      Header: t('user:labels.username'),
      headerProps: {
        className: 'px-3',
        style: { height: '46px' },
        onClick: () => handleSort('NormalizedDisplayName')
      },
      cellProps: {
        className: 'py-2 white-space-nowrap px-3 pe-xxl-4'
      },
      Cell: rowData => {
        const { fullName, avatarUrl, id } = rowData.row.original;
        return (
          <Flex alignItems="center" className="position-relative py-1 ">
            <Avatar
              src={toApiFileUrl(avatarUrl)}
              size="xl"
              className="me-2"
              name={fullName || t('common:labels.unknown')}
            />
            <Link
              to={`/user/profile/${id}`}
              className="stretched-link fw-semi-bold"
            >
              {fullName}
            </Link>
          </Flex>
        );
      }
    },
    {
      accessor: 'email',
      Header: t('user:labels.email'),
      headerProps: {
        style: {
          minWidth: '14.625rem',
          width: '150px'
        },
        onClick: () => handleSort('NormalizedEmail')
      },
      cellProps: {
        className: 'py-2 pe-4'
      },
      Cell: rowData => {
        const { email } = rowData.row.original;
        return (
          <p className="fw-semi-bold break-word m-0 mt-2">{`${truncateString(
            email,
            35
          )}`}</p>
        );
      }
    },
    {
      accessor: 'status',
      Header: t('user:labels.status'),
      headerProps: {
        onClick: () => handleSort('Status')
      },
      cellProps: {
        className: 'fs-0 ',
        style: {
          width: '100px'
        }
      },
      Cell: rowData => {
        const { status } = rowData.row.original;
        return (
          <SoftBadge
            bg={
              status === 'active'
                ? 'success'
                : status === 'inactive'
                ? 'danger'
                : status === 'pending'
                ? 'warning'
                : 'dark'
            }
            className="me-2 mt-2"
          >
            {status ? t(`common:status.${status}`) : t(`common:labels.unknown`)}
          </SoftBadge>
        );
      }
    },
    {
      accessor: 'lastLoginDate',
      Header: t('user:labels.lastLoginDate'),
      headerProps: {
        onClick: () => handleSort('LastLoginDate')
      },
      cellProps: {
        className: 'pe-4',
        style: {
          width: '180px'
        }
      },
      Cell: rowData => {
        const { lastLoginDate } = rowData.row.original;
        return (
          <p className="mb-0 mt-2">
            {lastLoginDate
              ? moment(lastLoginDate || new Date())
                  .locale(currentLanguage)
                  .format('DD/MM/YYYY hh:mm A')
              : t('common:labels.noDataAvailable')}
          </p>
        );
      }
    },
    {
      accessor: 'roleNames',
      Header: t('user:labels.roles'),
      headerProps: { className: 'text-start' },
      cellProps: {
        className: 'pe-4'
      },
      disableSortBy: true,
      Cell: rowData => {
        const { roleNames } = rowData.row.original;
        return roleNames?.map(role => {
          return (
            <SoftBadge key={role.id} bg="info" className="me-2 mt-2">
              {t(`common:roles.${role.name.toLowerCase().trim()}`)}
            </SoftBadge>
          );
        });
      }
    },
    {
      accessor: 'metadata.createdAt',
      Header: t('user:labels.createdAt'),
      headerProps: {
        className: 'text-start',
        onClick: () => handleSort('metadata.createdAt')
      },
      Cell: rowData => {
        const { metadata } = rowData.row.original;
        return (
          <p className="fw-semi-bold mb-0 mt-2">
            {metadata?.createdAt
              ? moment(metadata?.createdAt || new Date())
                  .locale(currentLanguage)
                  .format('YYYY-MM-DD hh:mm A')
              : t('common:labels.noDataAvailable')}
          </p>
        );
      }
    }
  ];

  return (
    <Card className={classNames(className, 'overflow-hidden mt-3')}>
      <Card.Header>{t('dashboard:labels.newUsers')}</Card.Header>
      <Card.Body className="p-0">
        {usersLoading &&
          Array(5)
            .fill(1)
            .map((n, i) => {
              return (
                <Placeholder
                  key={`users-table-skeleton-${i}`}
                  className="w-100 my-1"
                  style={{ height: '33px' }}
                  size="lg"
                />
              );
            })}
        {!usersLoading && (
          <AdvanceTableWrapper
            columns={columns}
            data={users || []}
            sortable
            rowCount={pagingInfo?.totalItems}
            pagination
            perPage={5}
            manualSortBy
          >
            <AdvanceTable
              table
              headerClassName="bg-200 text-900 text-nowrap align-middle font-sans-serif"
              rowClassName="btn-reveal-trigger text-nowrap align-middle"
              tableProps={{
                className: 'fs--1 fw-semi-bold mb-0 overflow-hidden'
              }}
            />
          </AdvanceTableWrapper>
        )}
        {!usersLoading && !users?.length && (
          <p className="w-100 text-center">
            {t('common:placeholder.noDataAvailable')}
          </p>
        )}
      </Card.Body>
      <Card.Footer>
        <AdvanceTablePagination
          table
          pageCount={pagingInfo?.totalPages || 0}
          pageIndex={pagingInfo?.currentPage || 0}
          gotoPage={page => {
            setFilters({ ...filters, pageNumber: page });
          }}
          isRtl={isRtl}
        />
      </Card.Footer>
    </Card>
  );
};

export default NewUsers;
