import React from 'react';
import PropTypes from 'prop-types';
import { CloseButton, Modal } from 'react-bootstrap';
import ReactVimeoPlayer from 'react-player/vimeo';

const ModalVideoContent = ({ show, setShow, attachment, onVideoError }) => {
  if (!attachment.src) {
    return '';
  }
  return (
    <Modal
      show={show}
      size="xl"
      onHide={() => setShow(false)}
      className="video-popup"
      contentClassName="bg-transparent border-0"
      backdropClassName="video-popup-backdrop"
      centered
    >
      <CloseButton
        variant="white"
        onClick={() => setShow(false)}
        className="video-popup-close-btn"
      />
      <Modal.Body className="p-0 rounded overflow-hidden">
        <ReactVimeoPlayer
          url={attachment.src}
          controls={true}
          className="react-player"
          poster={attachment.image}
          onError={onVideoError}
        />
      </Modal.Body>
    </Modal>
  );
};

ModalVideoContent.propTypes = {
  show: PropTypes.bool.isRequired,
  setShow: PropTypes.func.isRequired,
  attachment: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    image: PropTypes.string,
    src: PropTypes.string,
    title: PropTypes.string,
    date: PropTypes.string,
    type: PropTypes.string
  })
};

export default ModalVideoContent;
