import http from 'http/interceptor';
import { getDeviceId, getItemFromStore } from 'helpers/utils';
const currentLocale =
  getItemFromStore('preferredLanguage', 'en') ||
  process.env.REACT_APP_DEFAULT_LANGUAGE;
const BASE_AUTH_URL = `${process.env.REACT_APP_API_AUTH_URL}/api/${currentLocale}/${process.env.REACT_APP_API_VERSION}/identity`;
const LOGIN_URL = `${BASE_AUTH_URL}/login`;

const deviceId = getDeviceId();

const AuthService = {
  login: data => {
    return http.post(LOGIN_URL, data, {
      headers: { 'Content-Type': 'Application/Json', deviceId }
    });
  },
  logout: () => {
    setTimeout(() => {
      return new Promise.resolve(true);
    }, 500);
  }
};

export default AuthService;
