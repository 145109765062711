import React from 'react';
import { Card, Row } from 'react-bootstrap';
import LmsStatItem from './LmsStatItem';
import { useState } from 'react';
import StatisticsService from 'http/StatisticsService';
import { useEffect } from 'react';
import { useQuery } from 'react-query';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { getErrorMessage } from 'http/utils';

const kpisItems = [
  {
    id: 1,
    title: 'totalStudents',
    subTitle: 'totalUsers',
    amount: 4968,
    subAmount: 6456,
    icon: 'user-graduate',
    color: 'primary',
    className:
      'border-md-end border-bottom border-xxl-bottom-0 pb-3 p-xxl-0 ps-md-0'
  },
  {
    id: 2,
    title: 'totalCourses',
    subTitle: 'totalCoursesEnrolments',
    amount: 234,
    subAmount: 2343454,
    icon: 'book-open',
    color: 'info',
    className:
      'border-md-end border-bottom border-xxl-bottom-0 pb-3 p-xxl-0 ps-md-0'
  },
  {
    id: 3,
    title: 'totalLearningPaths',
    subTitle: 'totalLearningPathsEnrolments',
    amount: 123,
    subAmount: 1233,
    icon: 'book-open',
    color: 'success',
    className:
      'border-md-end border-bottom border-xxl-bottom-0 pb-3 p-xxl-0 ps-md-0'
  },
  {
    id: 4,
    title: 'totalInstructors',
    amount: 123,
    icon: 'chalkboard-teacher',
    color: 'warning',
    className:
      'border-md-end border-bottom border-xxl-bottom-0 pb-3 p-xxl-0 ps-md-0'
  },
  {
    id: 5,
    title: 'totalBlogs',
    subTitle: 'totalArticles',
    amount: 123,
    icon: 'file',
    color: 'danger',
    className:
      'border-md-end border-bottom border-xxl-bottom-0 pb-3 p-xxl-0 ps-md-0'
  }
];

const LmsStats = () => {
  const { t } = useTranslation();
  const [data, setData] = useState(null);

  const {
    isLoading: kpisLoading,
    error: kpisError,
    data: kpisData,
    refetch: fetchKpis
  } = useQuery('KPIS', () => StatisticsService.kpi(), {
    retry: false,
    manual: true, // Prevents automatic refetching
    refetchOnWindowFocus: false,
    enabled: false
  });

  useEffect(() => {
    if (kpisData && !kpisLoading && !kpisError) {
      if (!kpisData?.payload) {
        toast.error(t('error:404'), {
          theme: 'colored',
          autoClose: false
        });
      }
      setData(kpisData?.payload || null);
    }
    if (kpisError) {
      toast.error(getErrorMessage(t, kpisError), {
        theme: 'colored',
        autoClose: false
      });
    }
  }, [kpisLoading, kpisError, kpisData]);

  useEffect(() => {
    fetchKpis();
  }, []);

  return (
    <Card className="mb-3">
      <Card.Body className="px-xxl-0 pt-4">
        <Row className="g-0">
          {kpisItems.map((stat, i) => {
            let statData = {
              ...stat,
              amount: data?.[stat.title] || 0,
              subAmount: data?.[stat.subTitle] || 0
            };

            return (
              <LmsStatItem
                key={`stat-item-${stat.id}-${i}`}
                stat={statData}
                kpisLoading={kpisLoading}
              />
            );
          })}
        </Row>
      </Card.Body>
    </Card>
  );
};

export default LmsStats;
