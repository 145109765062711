import React, { useEffect, useState } from 'react';
import { Card, Col, Offcanvas, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useBreakpoints } from 'hooks/useBreakpoints';
import LoaderWithMessage from 'components/common/LoaderWithMessage';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { toast } from 'react-toastify';
import { getErrorMessage } from 'http/utils';
import AdvanceTablePagination from 'components/common/advance-table/AdvanceTablePagination';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import LearningPathService from 'http/LearningPathService';
import LearningPathListItem from './LearningPathListItem';
import LearningPathFilters from './components/LearningPathFilters';
import LearningPathListHeader from './components/LearningPathListHeader';

const LearningPathList = () => {
  const { t, i18n } = useTranslation();
  const isRtl = i18n.language === 'ar';
  const [learningPaths, setLearningPaths] = useState(null);
  const [showFilterOffcanvas, setShowFilterOffcanvas] = useState(false);
  const [learningPathPerPage] = useState(6);
  const [pagingInfo, setPagingInfo] = useState(null);
  const { breakpoints } = useBreakpoints();

  const {
    refetch: fetchLearningPaths,
    isLoading: learningPathsLoading,
    data: learningPathsData,
    error: learningPathsError
  } = useQuery(
    ['LearningPathsData', filters],
    () => LearningPathService.list(filters),
    {
      retry: false,
      manual: true, // Prevents automatic refetching
      refetchOnWindowFocus: false,
      enabled: false
    }
  );

  useEffect(() => {
    if (learningPathsData && !learningPathsLoading && !learningPathsError) {
      //console.log('learningPathsData', learningPathsData);
      if (learningPathsData?.errors?.length) {
        toast.error(getErrorMessage(t, learningPathsData?.errors), {
          theme: 'colored',
          autoClose: false
        });
      }
      setPagingInfo(learningPathsData?.payload?.pagingInfo);
      setLearningPaths(learningPathsData?.payload?.data);
    }

    if (learningPathsError) {
      toast.error(getErrorMessage(t, learningPathsError), {
        theme: 'colored',
        autoClose: false
      });
    }
  }, [learningPathsLoading, learningPathsData, learningPathsError]);

  const [filters, setFilters] = useState({
    pageNumber: 1,
    pageSize: learningPathPerPage,
    sortBy: 'metadata.createdAt',
    sortDirection: 'desc'
  });

  const handleFilter = newFilters => {
    setFilters({
      pageNumber: 1,
      pageSize: 6,
      sortBy: 'stats.rate',
      sortDirection: 'desc',
      ...newFilters
    });
  };

  useEffect(() => {
    fetchLearningPaths({
      payload: {
        ...filters
      }
    });
  }, [filters]);

  if (learningPathsError) {
    return (
      <Card className="bg-transparent shadow-none">
        <Card.Body className="border-2 border-dashed border-400 border rounded text-center py-5">
          <div className="fs--1">
            <FontAwesomeIcon icon={faTimesCircle} className="fs-6 mb-3" />
            <h5> {t('learningPath:message.learningPathsLoadingError')}</h5>
          </div>
        </Card.Body>
      </Card>
    );
  }

  return (
    <>
      <Row className="g-3">
        {breakpoints.up('xl') && (
          <Col xl={3}>
            <LearningPathFilters
              handleFilter={handleFilter}
              filtersData={filters}
            />
          </Col>
        )}
        <Col xl={9}>
          {/* LearningPaths Header */}
          <LearningPathListHeader
            layout="grid"
            setShowFilterOffcanvas={setShowFilterOffcanvas}
            t={t}
            filters={filters}
            handleFilter={handleFilter}
          />
          {/* LearningPaths */}
          <Row className="mb-3 g-3">
            {learningPathsLoading ? (
              <LoaderWithMessage
                message={t('learningPath:message.loadingLearningPaths')}
              />
            ) : !!learningPaths?.length && !learningPathsLoading ? (
              learningPaths.map(learningPath => (
                <Col key={learningPath.id} xs={12}>
                  <LearningPathListItem learningPath={learningPath} />
                </Col>
              ))
            ) : learningPaths !== null ? (
              <Card className="bg-transparent shadow-none">
                <Card.Body className="border-2 border-dashed border-400 border rounded text-center py-5">
                  <div className="fs--1">
                    <FontAwesomeIcon
                      icon="exclamation-triangle"
                      className="fs-6 mb-3"
                    />
                    <h5> {t('learningPath:message.noLearningPaths')}</h5>
                    <p className="mb-0">
                      {t('learningPath:message.noLearningPathsDescription')}
                    </p>
                  </div>
                </Card.Body>
              </Card>
            ) : (
              ''
            )}
          </Row>
          {/* LearningPath pagination */}
          {!!learningPaths?.length && (
            <Card>
              <Card.Body>
                <AdvanceTablePagination
                  pageCount={pagingInfo?.totalPages || 0}
                  pageIndex={pagingInfo?.currentPage || 1}
                  gotoPage={page => {
                    console.log('gotoPage', page);
                    setFilters({ ...filters, pageNumber: page });
                  }}
                  isRtl={isRtl}
                />
              </Card.Body>
            </Card>
          )}
        </Col>
      </Row>
      {breakpoints.down('xl') && (
        <Offcanvas
          show={showFilterOffcanvas}
          onHide={() => setShowFilterOffcanvas(false)}
          placement="start"
          className="offcanvas offcanvas-filter-sidebar"
        >
          <LearningPathFilters
            isOffcanvas={true}
            setShow={setShowFilterOffcanvas}
          />
        </Offcanvas>
      )}
    </>
  );
};

export default LearningPathList;
