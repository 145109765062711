import React from 'react';
import Error404 from 'components/errors/Error404';
import Error500 from 'components/errors/Error500';
import Dashboard from 'pages/dashboard';
import CourseList from 'pages/Operations/Course/Courses';
import CoursePage from 'pages/Operations/Course/CoursePage';
import LearningPathList from 'pages/Operations/LearningPath/LearningPathList';
import LearningPathPage from 'pages/Operations/LearningPath/LearningPathPage';
import BlogsList from 'pages/Operations/Blogs/BlogsList';
import CreateBlog from 'pages/Operations/Blogs/CreateBlog';
import Payments from 'pages/Reports/Payments';
import UserList from 'pages/User/UserList';
import LoginPage from 'pages/Auth/LoginPage';
import Settings from 'pages/User/settings/UserProfileSettings';
import Profile from 'pages/User/profile/Profile';
import CreateCourse from 'pages/Operations/Course/CreateCourse';
import CourseProvider from 'providers/CourseProvider';
import CreateLearningPath from 'pages/Operations/LearningPath/CreateLearningPath';
import LearningPathProvider from 'providers/LearningPathProvider';
import Error401 from 'components/errors/Error401';
import { faNewspaper } from '@fortawesome/free-regular-svg-icons';
import {
  faBookOpen,
  faFile,
  faGraduationCap,
  faGripLinesVertical,
  faMapSigns,
  faMoneyBill,
  faTag,
  faUsers,
  faVial
} from '@fortawesome/free-solid-svg-icons';
import StaticPagesList from 'pages/ContentManagement/ListStaticPages';
import CreateStaticPage from 'pages/ContentManagement/CreateStaticPage';
import PreviewStaticPage from 'pages/ContentManagement/PreviewStaticPage';
import ExamList from 'pages/Operations/Exam/ExamList';
import CreateExam from 'pages/Operations/Exam/CreateExam';
import ExamProvider from 'providers/ExamProvider';
import PreviewBlog from 'pages/Operations/Blogs/BlogPage';
import BlogProvider from 'providers/BlogProvider';
import DiscountsList from 'pages/Operations/Discounts/DiscountsList';
import CreateDiscount from 'pages/Operations/Discounts/CreateDiscount';

const CreateCoursePage = () => {
  return (
    <CourseProvider>
      <CreateCourse />
    </CourseProvider>
  );
};

const CreateLearningPathPage = () => {
  return (
    <LearningPathProvider>
      <CreateLearningPath />
    </LearningPathProvider>
  );
};

const CreateExamPage = () => {
  return (
    <ExamProvider>
      <CreateExam />
    </ExamProvider>
  );
};

const CreateBlogPage = () => {
  return (
    <BlogProvider>
      <CreateBlog />
    </BlogProvider>
  );
};

export const dashboardRoutes = {
  label: 'dashboard',
  //labelDisable: true,
  menu: true,
  layout: 'main',
  children: [
    {
      name: 'dashboard',
      active: true,
      icon: 'chart-pie',
      to: '/dashboard',
      path: '/dashboard',
      component: () => <Dashboard />,
      exact: true,
      roles: ['admin', 'stakeholder'],
      layout: 'main'
    }
  ],
  roles: ['admin', 'stakeholder']
};

export const adminRoutes = {
  label: 'admin',
  menu: true,
  layout: 'main',
  children: [
    {
      name: 'users',
      active: true,
      icon: faUsers,
      to: '/admin/users',
      path: '/admin/users',
      component: () => <UserList />,
      exact: true,
      roles: ['admin'],
      layout: 'main'
    }
  ],
  roles: ['admin']
};

export const userRoutes = {
  label: 'user',
  menu: true,
  layout: 'main',
  hidden: true,
  children: [
    {
      name: 'users',
      active: true,
      icon: faUsers,
      to: '/user/profile/:id?',
      path: '/user/profile/:id?',
      component: () => <Profile />,
      exact: true,
      hidden: true,
      layout: 'main'
    },
    {
      name: 'users',
      active: true,
      icon: faUsers,
      to: '/user/settings/:id?',
      path: '/user/settings/:id?',
      component: () => <Settings />,
      exact: true,
      hidden: true,
      layout: 'main'
    }
  ]
};

export const operationsRoutes = {
  label: 'operations',
  menu: true,
  layout: 'main',
  children: [
    {
      name: 'courses',
      active: true,
      icon: faGraduationCap,
      to: '/courses',
      path: '/courses',
      component: () => <CourseList />,
      exact: true,
      roles: ['admin', 'planner'],
      layout: 'main'
    },
    {
      name: 'learningPaths',
      active: true,
      icon: faMapSigns,
      to: '/learningPaths',
      path: '/learningPaths',
      component: () => <LearningPathList />,
      exact: true,
      roles: ['admin', 'planner'],
      layout: 'main'
    },
    {
      name: 'createCourse',
      active: true,
      icon: faGripLinesVertical,
      to: '/course/create',
      path: '/course/create/:id?',
      component: () => <CreateCoursePage />,
      exact: true,
      roles: ['admin', 'planner'],
      hidden: true,
      layout: 'main'
    },
    {
      name: 'createLearningPath',
      active: true,
      icon: faGripLinesVertical,
      to: '/learningPath/create',
      path: '/learningPath/create/:id?',
      component: () => <CreateLearningPathPage />,
      exact: true,
      roles: ['admin', 'planner'],
      hidden: true,
      layout: 'main'
    },
    {
      name: 'course',
      active: true,
      icon: faGripLinesVertical,
      to: '/course',
      path: '/course/:id?',
      component: () => <CoursePage />,
      exact: true,
      roles: ['admin', 'planner'],
      hidden: true,
      layout: 'main'
    },
    {
      name: 'viewLearningPath',
      active: true,
      icon: faGripLinesVertical,
      to: '/learningPath',
      path: '/learningPath/:id?',
      component: () => <LearningPathPage />,
      exact: true,
      roles: ['admin', 'planner'],
      hidden: true,
      layout: 'main'
    },
    {
      name: 'exams',
      active: true,
      icon: faVial,
      to: '/exams',
      path: '/exams',
      component: () => <ExamList />,
      exact: true,
      roles: ['admin', 'planner'],
      layout: 'main'
    },
    {
      name: 'createExam',
      active: true,
      icon: faGripLinesVertical,
      to: '/exams/create',
      path: '/exams/create/:id?',
      component: () => <CreateExamPage />,
      exact: true,
      roles: ['admin', 'planner'],
      hidden: true,
      layout: 'main'
    },
    {
      name: 'discounts',
      active: true,
      icon: faTag,
      to: '/discounts',
      path: '/discounts',
      component: () => <DiscountsList />,
      exact: true,
      roles: ['admin', 'marketing'],
      layout: 'main'
    },
    {
      name: 'createDiscount',
      active: true,
      icon: faNewspaper,
      to: '/discounts/create',
      path: '/discounts/create/:id?',
      component: () => <CreateDiscount />,
      exact: true,
      roles: ['admin', 'marketing'],
      hidden: true,
      layout: 'main'
    }
  ],
  roles: ['admin', 'marketing', 'planner']
};

export const contentManagementRoutes = {
  label: 'contentManagement',
  menu: true,
  layout: 'main',
  children: [
    {
      name: 'staticPages',
      active: true,
      icon: faFile,
      to: '/contents/pages',
      path: '/contents/pages',
      component: () => <StaticPagesList />,
      exact: true,
      roles: ['admin', 'marketing'],
      layout: 'main'
    },
    {
      name: 'createPage',
      active: true,
      icon: faNewspaper,
      to: '/contents/pages',
      path: '/contents/pages/create/:id?',
      component: () => <CreateStaticPage />,
      exact: true,
      roles: ['admin', 'marketing'],
      hidden: true,
      layout: 'main'
    },
    {
      name: 'previewPage',
      active: true,
      icon: faNewspaper,
      to: '/contents/pages/preview',
      path: '/contents/pages/preview/:id?',
      component: () => <PreviewStaticPage />,
      exact: true,
      roles: ['admin', 'marketing'],
      hidden: true,
      layout: 'main'
    },
    {
      name: 'blogsAndArticles',
      active: true,
      icon: faBookOpen,
      to: '/contents/blogs',
      path: '/contents/blogs',
      component: () => <BlogsList />,
      exact: true,
      roles: ['admin', 'marketing'],
      layout: 'main'
    },
    {
      name: 'createBlog',
      active: true,
      icon: faNewspaper,
      to: '/contents/blogs/create',
      path: '/contents/blogs/create/:id?',
      component: () => <CreateBlogPage />,
      exact: true,
      roles: ['admin', 'marketing'],
      hidden: true,
      layout: 'main'
    },
    {
      name: 'blog',
      active: true,
      icon: faNewspaper,
      to: '/contents/blogs/preview',
      path: '/contents/blogs/preview/:id?',
      component: () => <PreviewBlog />,
      exact: true,
      roles: ['admin', 'marketing'],
      hidden: true,
      layout: 'main'
    }
  ],
  roles: ['admin', 'marketing']
};

export const reportsRoutes = {
  label: 'reports',
  menu: true,
  layout: 'main',
  children: [
    {
      name: 'payments',
      active: true,
      icon: faMoneyBill,
      to: '/reports/payments',
      path: '/reports/payments',
      component: () => <Payments />,
      exact: true,
      roles: ['admin', 'stakeholder'],
      layout: 'main'
    }
  ],
  roles: ['admin', 'stakeholder'],
  hidden: true
};

export const authRoutes = {
  label: '',
  menu: false,
  layout: 'auth',
  children: [
    {
      name: 'login',
      active: true,
      icon: 'sign-in',
      to: '/login',
      path: '/login',
      component: () => <LoginPage />,
      exact: true,
      hidden: true,
      layout: 'auth'
    }
  ],
  hidden: true
};

export const errorRoutes = {
  label: '',
  menu: false,
  layout: 'error',
  hidden: true,
  children: [
    {
      name: '404',
      active: false,
      icon: '',
      to: '/not-found',
      path: '/not-found',
      component: () => <Error404 />,
      exact: true,
      hidden: true,
      layout: 'error'
    },
    {
      name: '401',
      active: false,
      icon: '',
      to: '/unauthorized',
      path: '/unauthorized',
      component: () => <Error401 />,
      exact: true,
      hidden: true,
      layout: 'error'
    },
    {
      name: '500',
      active: false,
      icon: '',
      to: '/error',
      path: '/error',
      component: () => <Error500 />,
      exact: true,
      hidden: true,
      layout: 'error'
    }
  ]
};

export default [
  dashboardRoutes,
  adminRoutes,
  userRoutes,
  operationsRoutes,
  contentManagementRoutes,
  reportsRoutes,
  authRoutes
];

export const flattenRoutes = [
  dashboardRoutes,
  userRoutes,
  adminRoutes,
  operationsRoutes,
  contentManagementRoutes,
  reportsRoutes,
  errorRoutes,
  authRoutes
]
  ?.map(r => r.children || [])
  .flat(1);
