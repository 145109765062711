import {
  faEdit,
  faGripLines,
  faTrash
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { truncateString } from 'helpers/utils';
import React from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import Flex from 'components/common/Flex';

const ExamQuestion = ({
  t,
  question,
  index,
  isReadOnly,
  breakpoints,
  setTotalUsedScorePoints,
  totalUsedScorePoints,
  setNewQuestion,
  handleClickDeleteQuestion,
  currentLanguage
}) => {
  return (
    <Draggable
      draggableId={`${question?.type}-exam-q-${index}`}
      index={index}
      disabled={isReadOnly}
    >
      {provided => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          className={`fs--1 mb-0 d-flex align-items-center justify-content-between m-2 border-grey border-1 border rounded mb-2 gap-2 ${
            breakpoints.down('md') ? 'p-0' : 'p-3'
          }`}
        >
          <Flex gap="2">
            <div
              {...provided.dragHandleProps}
              style={{ cursor: 'grab' }}
              className="d-flex align-items-center"
            >
              {!isReadOnly && (
                <FontAwesomeIcon
                  icon={faGripLines}
                  size={breakpoints.down('md') ? 'md' : 'lg'}
                />
              )}
            </div>
            <div
              className="mb-0 no-mb"
              dangerouslySetInnerHTML={{
                __html: truncateString(question?.content?.[currentLanguage], 50)
              }}
            >
              {}
            </div>
          </Flex>
          <div>
            <span>{t(`exams:questionTypes.${question?.type}`)}</span>,
            <span className="mx-1">
              {t('exams:labels.score')} {`${question?.score || 0}`}
            </span>
            {!isReadOnly && (
              <>
                <OverlayTrigger
                  placement="top"
                  overlay={
                    <Tooltip
                      id={'tooltip-edit-question-' + question.id || `${index}`}
                    >
                      {t('common:button.edit')}
                    </Tooltip>
                  }
                >
                  <span className="ms-2 text-primary fs-0">
                    <FontAwesomeIcon
                      icon={faEdit}
                      className="cursor-pointer"
                      size="sm"
                      onClick={() => {
                        setTotalUsedScorePoints(
                          totalUsedScorePoints - (question?.score || 0)
                        );
                        setNewQuestion(question);
                      }}
                    />
                  </span>
                </OverlayTrigger>
                <OverlayTrigger
                  placement="top"
                  overlay={
                    <Tooltip
                      id={
                        'tooltip-delete-question-' + question.id || `${index}`
                      }
                    >
                      {t('common:button.delete')}
                    </Tooltip>
                  }
                >
                  <span className="ms-2 text-danger fs-0">
                    <FontAwesomeIcon
                      icon={faTrash}
                      className="cursor-pointer"
                      size="sm"
                      onClick={() => handleClickDeleteQuestion(question.id)}
                    />
                  </span>
                </OverlayTrigger>
              </>
            )}
          </div>
        </div>
      )}
    </Draggable>
  );
};

export default ExamQuestion;
