import React from 'react';
import { Button, Card, Col, Form, Row, Spinner } from 'react-bootstrap';

import { useState } from 'react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { getErrorMessage } from 'http/utils';
import StaticPagesService from 'http/StaticPagesService';
import { toStaticPageFormSchema } from './utils';
import LoaderWithMessage from 'components/common/LoaderWithMessage';
import ConfirmModal from 'components/common/ConfirmModal';
import MultiLanguageRTE from 'components/common/MultiLanguageRTE';

const initPage = {
  title: {
    ar: '',
    en: ''
  },
  keywords: {
    ar: '',
    en: ''
  },
  description: {
    ar: '',
    en: ''
  },
  content: {
    ar: undefined,
    en: undefined
  }
};

const optionalFields = ['pageKeywordsAr', 'pageKeywordsEn'];

const CreateStaticPage = () => {
  const { id } = useParams();
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const currentLanguage = i18n.language;
  const [page, setPage] = useState(initPage);
  const [pageValues, setPageValues] = useState(null);
  const [formKey, setFormKey] = useState(Date.now()); // Create a state variable to force re-render
  const [errors, setErrors] = useState(null);
  const [isEdit, setIsEdit] = useState(false);
  const [confirmModalProps, setConfirmModalProps] = useState(null);

  const {
    isLoading: staticPageLoading,
    error: staticPageError,
    data: staticPageData,
    refetch: fetchStaticPage
  } = useQuery(['StaticPage', id], () => StaticPagesService.get(id), {
    retry: false,
    manual: true, // Prevents automatic refetching
    refetchOnWindowFocus: false,
    enabled: false
  });

  useEffect(() => {
    if (id) {
      fetchStaticPage(id);
    } else {
      setPageValues(toStaticPageFormSchema({}));
    }
  }, [id]);

  const {
    mutate: createStaticPage,
    error: staticPageCreateError,
    isLoading: staticPageCreateLoading
  } = useMutation({
    mutationFn: StaticPagesService.save,
    onSuccess: data => onCreateStaticPageSuccess(data)
  });

  const fillInitialData = data => {
    if (!data) {
      return;
    }
    const formattedData = toStaticPageFormSchema(data);
    //console.log('formattedData', formattedData);
    setPageValues(formattedData);
    setFormKey(Date.now());
  };

  useEffect(() => {
    if (staticPageData && !staticPageLoading && !staticPageError) {
      if (!staticPageData?.payload) {
        toast.error(t('error:404'), {
          theme: 'colored',
          autoClose: false
        });
        navigate(`/contents/pages`);
      }
      setPage(staticPageData?.payload || null);
      fillInitialData(staticPageData?.payload || null);
    }
    if (staticPageError) {
      toast.error(getErrorMessage(t, staticPageError), {
        theme: 'colored',
        autoClose: false
      });
    }
  }, [staticPageLoading, staticPageError, staticPageData]);

  const onSubmit = () => {
    const isValid = validateForm(pageValues);
    if (!isValid) {
      toast.error(t('common:validation.pleaseFilloutRequiredFields'), {
        theme: 'colored',
        autoClose: false
      });
      return;
    }
    const values = { ...pageValues };

    let createPayload = {
      title: {
        ar: values.pageTitleAr,
        en: values.pageTitleEn
      },
      keywords: {
        ar: values.pageKeywordsAr,
        en: values.pageKeywordsEn
      },
      description: {
        ar: values.pageDescriptionAr,
        en: values.pageDescriptionEn
      },
      content: {
        ar: values.pageContentAr,
        en: values.pageContentEn
      }
    };
    if (page?.id) {
      createPayload['id'] = page?.id;
    }
    createStaticPage(createPayload);
  };
  const onCreateStaticPageSuccess = response => {
    //console.log('response', response);   ///,kjnlk;lk;l;kmlk;
    if (typeof response?.payload === 'string' && !page?.id) {
      navigate(`/contents/pages/create/${response.payload}`);
    }
    toast.success(
      t(`common:message.${!page?.id ? 'createSuccess' : 'updateSuccess'}`),
      { theme: 'colored' }
    );
  };

  const validateForm = data => {
    let valid = true;
    let errorsObj = {};
    if (!data) {
      toast.error(t('common:validation.pleaseFilloutRequiredFields'), {
        theme: 'colored',
        autoClose: false
      });
      return false;
    }
    Object.entries(data).map(([key, value]) => {
      if (!value && !optionalFields.includes(key)) {
        errorsObj[key] = {
          error: true,
          message: t('common:validation.fieldIsRequired')
        };
        valid = false;
      } else {
        if (key === 'pageTitleAr') {
          if (value?.length > 60) {
            errorsObj['pageTitleAr'] = {
              error: true,
              message: t('course:validation.message.maxAllowedCharacters', {
                count: 60
              })
            };
            valid = false;
          }
        } else if (key === 'pageTitleEn') {
          if (value?.length > 60) {
            errorsObj['pageTitleEn'] = {
              error: true,
              message: t('course:validation.message.maxAllowedCharacters', {
                count: 60
              })
            };
            valid = false;
          }
        } else if (key === 'pageKeywordsAr') {
          if (value?.length > 60) {
            errorsObj['pageKeywordsAr'] = {
              error: true,
              message: t('course:validation.message.maxAllowedCharacters', {
                count: 60
              })
            };
            valid = false;
          }
        } else if (key === 'pageKeywordsEn') {
          if (value?.length > 60) {
            errorsObj['pageKeywordsEn'] = {
              error: true,
              message: t('course:validation.message.maxAllowedCharacters', {
                count: 60
              })
            };
            valid = false;
          }
        } else {
          errorsObj[key] = null;
        }
      }
    });
    setErrors(errorsObj);
    console.log('onError', errorsObj);
    return valid;
  };

  const handleChange = (value, name) => {
    setErrors({ ...errors, [name]: null });
    setPageValues({ ...pageValues, [name]: value });
  };

  const onCancel = () => {
    if (isEdit) {
      fillInitialData(page);
      setIsEdit(false);
      return;
    }
    // console.log('page', toStaticPageFormSchema(page));
    // console.log('values', { ...pageValues });
    const hasChanges =
      JSON.stringify({ ...toStaticPageFormSchema(page) }) !==
      JSON.stringify({ ...pageValues });
    if (hasChanges) {
      setConfirmModalProps({
        isOpen: true,
        onClose: () => setConfirmModalProps(null),
        onConfirm: () => navigate('/contents/pages'),
        message: t('staticPages:message.discardPageConfirm'),
        header: t('staticPages:message.discardPageTitle'),
        actionButtonColor: 'danger',
        actionButtonText: t('common:button.discard')
      });
      return;
    }
    navigate('/contents/pages');
  };

  useEffect(() => {
    if (staticPageCreateError) {
      toast.error(getErrorMessage(t, staticPageCreateError), {
        theme: 'colored',
        autoClose: false
      });
    }
  }, [staticPageCreateError]);

  if (staticPageLoading) {
    return (
      <LoaderWithMessage message={t('staticPages:message.loadingStaticPage')} />
    );
  }

  return (
    <Card className="mb-3">
      <Card.Header as="h5">
        {!!id
          ? page?.title?.[currentLanguage]
          : t('staticPages:labels.newStaticPage')}
      </Card.Header>
      <Card.Body className="bg-light">
        <Row className="gx-2 gy-3" key={formKey}>
          <Col md="6">
            <Form.Group controlId="pageTitleAr">
              <Form.Label>
                {`${t('staticPages:labels.title')} (${t(
                  'common:language.ar.name'
                )})`}
                <span className="text-danger">*</span>
                <small>
                  {`(${t('common:labels.maxCharacters', { count: 60 })})`}
                </small>
              </Form.Label>
              <Form.Control
                type="text"
                name="pageTitleAr"
                required
                placeholder={t('staticPages:labels.title')}
                onChange={e => handleChange(e.target.value, e.target.name)}
                value={pageValues?.pageTitleAr || ''}
                disabled={
                  staticPageCreateLoading ||
                  staticPageLoading ||
                  (!!id && !isEdit)
                }
                isInvalid={!!errors?.pageTitleAr?.error}
              />
              {!!errors?.pageTitleAr?.error && (
                <Form.Text className="text-danger">
                  {errors?.pageTitleAr?.message}
                </Form.Text>
              )}
            </Form.Group>
          </Col>
          <Col md="6">
            <Form.Group controlId="pageTitleEn">
              <Form.Label>
                {`${t('staticPages:labels.title')} (${t(
                  'common:language.en.name'
                )})`}
                <span className="text-danger">*</span>
                <small>
                  {` (${t('common:labels.maxCharacters', { count: 60 })})`}
                </small>
              </Form.Label>
              <Form.Control
                type="text"
                name="pageTitleEn"
                required
                placeholder={t('staticPages:labels.title')}
                onChange={e => handleChange(e.target.value, e.target.name)}
                value={pageValues?.pageTitleEn || ''}
                disabled={
                  staticPageCreateLoading ||
                  staticPageLoading ||
                  (!!id && !isEdit)
                }
                isInvalid={!!errors?.pageTitleEn?.error}
              />
              {!!errors?.pageTitleEn?.error && (
                <Form.Text className="text-danger">
                  {errors?.pageTitleEn?.message}
                </Form.Text>
              )}
            </Form.Group>
          </Col>
          <Col md="6">
            <Form.Group controlId="pageKeywordsAr">
              <Form.Label>
                {`${t('staticPages:labels.keywords')} (${t(
                  'common:language.ar.name'
                )})`}
                <small>
                  {` (${t('common:labels.maxCharacters', { count: 60 })})`}
                </small>
              </Form.Label>
              <Form.Control
                type="text"
                name="pageKeywordsAr"
                required
                placeholder={t('staticPages:labels.keywords')}
                onChange={e => handleChange(e.target.value, e.target.name)}
                value={pageValues?.pageKeywordsAr || ''}
                disabled={
                  staticPageCreateLoading ||
                  staticPageLoading ||
                  (!!id && !isEdit)
                }
                isInvalid={!!errors?.pageKeywordsAr?.error}
              />
              {!!errors?.pageKeywordsAr?.error && (
                <Form.Text className="text-danger">
                  {errors?.pageKeywordsAr?.message}
                </Form.Text>
              )}
            </Form.Group>
          </Col>
          <Col md="6">
            <Form.Group controlId="pageKeywordsEn">
              <Form.Label>
                {`${t('staticPages:labels.keywords')} (${t(
                  'common:language.en.name'
                )})`}
                <small>
                  {` (${t('common:labels.maxCharacters', { count: 60 })})`}
                </small>
              </Form.Label>
              <Form.Control
                type="text"
                name="pageKeywordsEn"
                required
                placeholder={t('staticPages:labels.keywords')}
                onChange={e => handleChange(e.target.value, e.target.name)}
                value={pageValues?.pageKeywordsEn || ''}
                disabled={
                  staticPageCreateLoading ||
                  staticPageLoading ||
                  (!!id && !isEdit)
                }
                isInvalid={!!errors?.pageKeywordsEn?.error}
              />
              {!!errors?.pageKeywordsEn?.error && (
                <Form.Text className="text-danger">
                  {errors?.pageKeywordsEn?.message}
                </Form.Text>
              )}
            </Form.Group>
          </Col>
          <Col md="6">
            <Form.Group controlId="pageDescriptionAr">
              <Form.Label>
                {`${t('staticPages:labels.description')} (${t(
                  'common:language.ar.name'
                )})`}
                <span className="text-danger">*</span>
                <small>
                  {`(${t('common:labels.maxCharacters', { count: 150 })})`}
                </small>
              </Form.Label>
              <Form.Control
                as="textarea"
                name="pageDescriptionAr"
                required
                placeholder={t('staticPages:labels.description')}
                onChange={e => handleChange(e.target.value, e.target.name)}
                value={pageValues?.pageDescriptionAr || ''}
                disabled={
                  staticPageCreateLoading ||
                  staticPageLoading ||
                  (!!id && !isEdit)
                }
                isInvalid={!!errors?.pageDescriptionAr}
              />
              {!!errors?.pageDescriptionAr?.error && (
                <Form.Text className="text-danger">
                  {errors?.pageDescriptionAr?.message}
                </Form.Text>
              )}
            </Form.Group>
          </Col>
          <Col md="6">
            <Form.Group controlId="pageDescriptionEn">
              <Form.Label>
                {`${t('staticPages:labels.description')} (${t(
                  'common:language.en.name'
                )})`}
                <span className="text-danger">*</span>
                <small>
                  {`(${t('common:labels.maxCharacters', { count: 150 })})`}
                </small>
              </Form.Label>
              <Form.Control
                as="textarea"
                name="pageDescriptionEn"
                required
                placeholder={t('staticPages:labels.description')}
                onChange={e => handleChange(e.target.value, e.target.name)}
                value={pageValues?.pageDescriptionEn || ''}
                disabled={
                  staticPageCreateLoading ||
                  staticPageLoading ||
                  (!!id && !isEdit)
                }
                isInvalid={!!errors?.pageDescriptionEn}
              />
              {!!errors?.pageDescriptionEn?.error && (
                <Form.Text className="text-danger">
                  {errors?.pageDescriptionEn?.message}
                </Form.Text>
              )}
            </Form.Group>
          </Col>
          <Col xs="12">
            <Form.Label>
              {`${t('staticPages:labels.content')}`}
              <span className="text-danger">*</span>
            </Form.Label>
            <MultiLanguageRTE
              t={t}
              enableFullscreen
              activeLanguage={currentLanguage}
              valueEn={pageValues?.pageContentEn || ''}
              valueAr={pageValues?.pageContentAr || ''}
              errorEn={!!errors?.pageContentEn}
              errorAr={!!errors?.pageContentAr}
              errorArMessage={t('common:validation.fieldIsRequired')}
              errorEnMessage={t('common:validation.fieldIsRequired')}
              placeholderEn={t('course:placeholders.addNewItem')}
              placeholderAr={t('course:placeholders.addNewItem')}
              handleEnChange={newValue => {
                handleChange(newValue || '', 'pageContentEn');
              }}
              handleArChange={newValue => {
                handleChange(newValue || '', 'pageContentAr');
              }}
              disabled={
                staticPageCreateLoading ||
                staticPageLoading ||
                (!!id && !isEdit)
              }
            />
          </Col>
        </Row>
      </Card.Body>

      <Card.Footer>
        {!id && (
          <>
            <Button size="sm" onClick={onSubmit}>
              {staticPageCreateLoading && <Spinner size="sm" />}
              {!staticPageCreateLoading && t('common:button.save')}
            </Button>
            <Button
              size="sm"
              variant="danger"
              onClick={onCancel}
              className="mx-2"
            >
              {!staticPageCreateLoading && t('common:button.cancel')}
            </Button>
          </>
        )}
        {isEdit && !!id && (
          <>
            <Button
              size="sm"
              onClick={onSubmit}
              disabled={!!staticPageCreateLoading || staticPageLoading}
            >
              {staticPageCreateLoading && <Spinner size="sm" />}
              {!staticPageCreateLoading && t('common:button.save')}
            </Button>

            <Button
              size="sm"
              variant="danger"
              onClick={onCancel}
              className="mx-2"
              disabled={!!staticPageCreateLoading || staticPageLoading}
            >
              {t('common:button.cancel')}
            </Button>
          </>
        )}
        {!isEdit && !!id && (
          <>
            <Button
              size="sm"
              variant="info"
              onClick={() => setIsEdit(true)}
              disabled={!!staticPageCreateLoading || staticPageLoading}
            >
              {t('common:button.edit')}
            </Button>
          </>
        )}
      </Card.Footer>
      {!!confirmModalProps && (
        <ConfirmModal {...confirmModalProps} loading={false} />
      )}
    </Card>
  );
};
export default CreateStaticPage;
