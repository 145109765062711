import React, { useEffect, useState } from 'react';
import { Card, Col, Offcanvas, Row } from 'react-bootstrap';
import CourseListItem from './CourseListItem';
import CourseListHeader from './components/CourseListHeader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CourseFilters from './components/CourseFilters';
import { useBreakpoints } from 'hooks/useBreakpoints';
import LoaderWithMessage from 'components/common/LoaderWithMessage';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import CourseService from 'http/CourseService';
import { toast } from 'react-toastify';
import { getErrorMessage } from 'http/utils';
import AdvanceTablePagination from 'components/common/advance-table/AdvanceTablePagination';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';

const Courses = () => {
  const { t, i18n } = useTranslation();
  const isRtl = i18n.language === 'ar';
  const [courses, setCourses] = useState(null);
  const [showFilterOffcanvas, setShowFilterOffcanvas] = useState(false);
  const [coursePerPage] = useState(6);
  const [pagingInfo, setPagingInfo] = useState(null);
  const { breakpoints } = useBreakpoints();

  const {
    refetch: fetchCourses,
    isLoading: coursesLoading,
    data: coursesData,
    error: coursesError
  } = useQuery(['CoursesData', filters], () => CourseService.list(filters), {
    retry: false,
    manual: true, // Prevents automatic refetching
    refetchOnWindowFocus: false,
    enabled: false
  });

  useEffect(() => {
    if (coursesData && !coursesLoading && !coursesError) {
      //console.log('coursesData', coursesData);
      if (coursesData?.errors?.length) {
        toast.error(getErrorMessage(t, coursesData?.errors), {
          theme: 'colored',
          autoClose: false
        });
      }
      setPagingInfo(coursesData?.payload?.pagingInfo);
      setCourses(coursesData?.payload?.data);
    }

    if (coursesError) {
      toast.error(getErrorMessage(t, coursesError), {
        theme: 'colored',
        autoClose: false
      });
    }
  }, [coursesLoading, coursesData, coursesError]);

  const [filters, setFilters] = useState({
    pageNumber: 1,
    pageSize: coursePerPage,
    sortBy: 'metadata.createdAt',
    sortDirection: 'desc'
  });

  const handleFilter = newFilters => {
    setFilters({
      pageNumber: 1,
      pageSize: 6,
      sortBy: 'stats.rate',
      sortDirection: 'desc',
      ...newFilters
    });
  };

  useEffect(() => {
    fetchCourses({
      payload: {
        ...filters
      }
    });
  }, [filters]);

  if (coursesError) {
    return (
      <Card className="bg-transparent shadow-none">
        <Card.Body className="border-2 border-dashed border-400 border rounded text-center py-5">
          <div className="fs--1">
            <FontAwesomeIcon icon={faTimesCircle} className="fs-6 mb-3" />
            <h5> {t('course:message.coursesLoadingError')}</h5>
          </div>
        </Card.Body>
      </Card>
    );
  }

  return (
    <>
      <Row className="g-3">
        {breakpoints.up('xl') && (
          <Col xl={3}>
            <CourseFilters handleFilter={handleFilter} filtersData={filters} />
          </Col>
        )}
        <Col xl={9}>
          {/* Courses Header */}
          <CourseListHeader
            layout="grid"
            setShowFilterOffcanvas={setShowFilterOffcanvas}
            t={t}
            filters={filters}
            handleFilter={handleFilter}
          />
          {/* Courses */}
          <Row className="mb-3 g-3">
            {coursesLoading ? (
              <LoaderWithMessage message={t('course:message.loadingCourses')} />
            ) : !!courses?.length ? (
              courses.map(course => (
                <Col key={course.id} xs={12}>
                  <CourseListItem course={course} />
                </Col>
              ))
            ) : courses !== null ? (
              <Card className="bg-transparent shadow-none">
                <Card.Body className="border-2 border-dashed border-400 border rounded text-center py-5">
                  <div className="fs--1">
                    <FontAwesomeIcon
                      icon="exclamation-triangle"
                      className="fs-6 mb-3"
                    />
                    <h5> {t('course:message.noCourses')}</h5>
                    <p className="mb-0">
                      {t('course:message.noCoursesDescription')}
                    </p>
                  </div>
                </Card.Body>
              </Card>
            ) : (
              ''
            )}
          </Row>
          {/* Course pagination */}
          {!!courses?.length && (
            <Card>
              <Card.Body>
                <AdvanceTablePagination
                  pageCount={pagingInfo?.totalPages || 0}
                  pageIndex={pagingInfo?.currentPage || 1}
                  gotoPage={page => {
                    console.log('gotoPage', page);
                    setFilters({ ...filters, pageNumber: page });
                  }}
                  isRtl={isRtl}
                />
              </Card.Body>
            </Card>
          )}
        </Col>
      </Row>
      {breakpoints.down('xl') && (
        <Offcanvas
          show={showFilterOffcanvas}
          onHide={() => setShowFilterOffcanvas(false)}
          placement="start"
          className="offcanvas offcanvas-filter-sidebar"
        >
          <CourseFilters isOffcanvas={true} setShow={setShowFilterOffcanvas} />
        </Offcanvas>
      )}
    </>
  );
};

export default Courses;
