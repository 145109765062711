import http from 'http/interceptor';
import { getItemFromStore } from 'helpers/utils';
const currentLocale =
  getItemFromStore('preferredLanguage', 'en') ||
  process.env.REACT_APP_DEFAULT_LANGUAGE;
const BASE_API_URL = `${process.env.REACT_APP_API_BASE_URL}/api/${currentLocale}/${process.env.REACT_APP_API_VERSION}`;
const KPI_URL = `${BASE_API_URL}/statistics/kpis`;
const USER_OVER_PERIOD_URL = `${BASE_API_URL}/statistics/users/overPeriod`;
const ENROLLMENTS_OVER_PERIOD_URL = `${BASE_API_URL}/statistics/enrollemts/overPeriod`;
const COURSES_OVER_PERIOD_URL = `${BASE_API_URL}/statistics/courses/overPeriod`;
const COURSES_REVENUES_OVER_PERIOD_URL = `${BASE_API_URL}/statistics/revenues/period`;
const PAYMENTS_URL = `${BASE_API_URL}/reports/purchaseDetails`;

const StatisticsService = {
  kpi: () => {
    return http.get(KPI_URL, {
      headers: { 'Content-Type': 'Application/Json' }
    });
  },
  usersOverPeriod: data => {
    return http.post(
      USER_OVER_PERIOD_URL,
      { ...data },
      {
        headers: { 'Content-Type': 'Application/Json' }
      }
    );
  },
  enrollmentsOverPeriod: data => {
    return http.post(
      ENROLLMENTS_OVER_PERIOD_URL,
      { ...data },
      {
        headers: { 'Content-Type': 'Application/Json' }
      }
    );
  },
  coursesOverPeriod: data => {
    return http.post(
      COURSES_OVER_PERIOD_URL,
      { ...data },
      {
        headers: { 'Content-Type': 'Application/Json' }
      }
    );
  },
  coursesRevenuesOverPeriod: data => {
    return http.post(
      COURSES_REVENUES_OVER_PERIOD_URL,
      { ...data },
      {
        headers: { 'Content-Type': 'Application/Json' }
      }
    );
  },
  payments: data => {
    return http.post(
      PAYMENTS_URL,
      { ...data },
      {
        headers: { 'Content-Type': 'Application/Json' }
      }
    );
  }
};

export default StatisticsService;
