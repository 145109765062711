import React, { useContext, useState, useEffect } from 'react';
import {
  Button,
  Card,
  Col,
  OverlayTrigger,
  Row,
  Spinner,
  Tooltip
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import { DragDropContext } from 'react-beautiful-dnd';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import { getErrorMessage } from 'http/utils';
import { generateRandomPassword, sumProperty } from 'helpers/utils';
import { useBreakpoints } from 'hooks/useBreakpoints';
import { ExamContext } from 'context/Context';
import ConfirmModal from 'components/common/ConfirmModal';
import StrictModeDroppable from 'components/common/StrictModeDroppable';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import Flex from 'components/common/Flex';
import ExamService from 'http/ExamService';
import QuestionModal from './QuestionModal';

import ExamQuestion from './ExamQuestion';

const initQuestion = {
  title: {
    ar: '',
    en: ''
  }
};

const ExamQuestions = () => {
  const { t, i18n } = useTranslation();
  const { breakpoints } = useBreakpoints();
  const {
    exam,
    isReadOnly,
    isCreate,
    fetchExam,
    examLoading,
    editCard,
    setEditCard
  } = useContext(ExamContext);
  const currentLanguage = i18n.language;
  const [questions, setQuestions] = useState([]);
  const [confirmModalProps, setConfirmModalProps] = useState(null);
  const [newQuestion, setNewQuestion] = useState(null);
  const [totalUsedScorePoints, setTotalUsedScorePoints] = useState(100);
  const [isEdit, setIsEdit] = useState(false);
  const [allowAddQuestions, setAllowAddQuestions] = useState(false);

  useEffect(() => {
    if (isEdit && !isReadOnly && totalUsedScorePoints < 100) {
      setAllowAddQuestions(true);
    }
  }, [isEdit, isReadOnly, totalUsedScorePoints]);

  useEffect(() => {
    if (editCard === 'questions' && !!exam?.id) {
      setIsEdit(true);
    } else {
      setIsEdit(false);
    }
  }, [exam, editCard]);

  useEffect(() => {
    if (exam) {
      setQuestions(exam?.questions || []);
    }
  }, [exam]);

  useEffect(() => {
    if (questions?.length) {
      let allQuestionsScore = 0;
      questions?.map(q => {
        allQuestionsScore += q?.score || 0;
        return q;
      });
      setTotalUsedScorePoints(allQuestionsScore);
    } else {
      setTotalUsedScorePoints(0);
    }
  }, [questions]);

  const {
    mutate: saveQuestions,
    error: saveQuestionsError,
    isLoading: saveQuestionsLoading
  } = useMutation({
    mutationFn: ExamService.createQuestions,
    onSuccess: data => onSaveQuestionsSuccess(data)
  });

  const onSaveQuestionsSuccess = () => {
    setEditCard(null);
    setConfirmModalProps(null);
    fetchExam(exam?.id);
    toast.success(t(`learningPath:message.updateSuccess`), {
      theme: 'colored'
    });
  };

  const handleSubmit = () => {
    saveQuestions({
      id: exam?.id,
      title: exam?.title,
      questions: questions?.map((q, index) => {
        return {
          ...q,
          rank: index,
          answers: q?.answers?.filter(a => !!a?.content?.ar && !!a?.content?.en)
        };
      })
    });
  };

  const handleCancel = () => {
    setAllowAddQuestions(false);
    setEditCard(null);
  };

  const reorder = (array, fromIndex, toIndex) => {
    const newArr = [...array];

    const chosenItem = newArr.splice(fromIndex, 1)[0];
    newArr.splice(toIndex, 0, chosenItem);

    return newArr;
  };

  const onDragEnd = result => {
    const { source, destination } = result;
    if (!destination) {
      return;
    }
    if (source.index === destination.index) {
      return;
    }
    const items = [...questions];
    const reorderedItems = reorder(items, source.index, destination.index);

    setQuestions(reorderedItems);
  };

  const newQuestionClick = () => {
    setNewQuestion({
      ...initQuestion,
      id: generateRandomPassword(5),
      isNew: true
    });
  };

  const handleAddQuestion = newQuestionData => {
    if (questions?.find(q => q.id === newQuestionData.id)) {
      let oldData = [...questions];
      const index = oldData.findIndex(obj => obj.id === newQuestionData.id);
      if (index !== -1) {
        oldData[index] = newQuestionData;
      }
      setQuestions([...oldData]);
    } else {
      setQuestions([...questions, newQuestionData]);
    }

    setNewQuestion(null);
  };

  const handleClickDeleteQuestion = id => {
    setConfirmModalProps({
      isOpen: true,
      onClose: () => setConfirmModalProps(null),
      onConfirm: () => handleDeleteQuestion(id),
      message: t('exams:message.confirmDeleteQuestionMessage'),
      header: t('exams:message.confirmDeleteQuestionTitle'),
      actionButtonColor: 'danger',
      actionButtonText: t('common:button.delete')
    });
  };

  const handleDeleteQuestion = id => {
    setQuestions([...(questions?.filter(q => q.id !== id) || [])]);
    setConfirmModalProps(null);
  };

  useEffect(() => {
    if (saveQuestionsError) {
      toast.error(getErrorMessage(t, saveQuestionsError), {
        theme: 'colored',
        autoClose: false
      });
    }
  }, [saveQuestionsError]);

  if (isCreate) {
    return '';
  }

  return (
    <Card className="mb-3">
      <Card.Header>
        <Flex className="w-100" justifyContent="between" alignItems="center">
          <h5>
            {t('exams:labels.questions')}
            {allowAddQuestions && (
              <OverlayTrigger
                overlay={
                  <Tooltip id={`exam-questions-add-toolip-${exam?.id}`}>
                    {t('exams:labels.addNewQuestion')}
                  </Tooltip>
                }
              >
                <span style={{ height: '1.5rem' }}>
                  <FontAwesomeIcon
                    icon={faPlus}
                    className="cursor-pointer mx-3"
                    onClick={newQuestionClick}
                    size="sm"
                  />
                </span>
              </OverlayTrigger>
            )}
          </h5>
          <span className="mx-1">
            {t('exams:labels.totalScore', {
              score: sumProperty(questions, 'score')
            })}
          </span>
        </Flex>
      </Card.Header>
      <Card.Body className="p-0">
        <DragDropContext onDragEnd={onDragEnd}>
          <Row>
            <Col auto="true">
              <StrictModeDroppable droppableId="droppable1" type="DRAG">
                {provided => (
                  <div
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                    className=" border bg-white dark__bg-1000 rounded-2  mb-3 p-0 overflow-visible"
                  >
                    {questions?.map((question, index) => {
                      return (
                        <ExamQuestion
                          key={`${question?.type}-exam-q-${index}`}
                          t={t}
                          question={question}
                          currentLanguage={currentLanguage}
                          index={index}
                          isReadOnly={!isEdit}
                          breakpoints={breakpoints}
                          setTotalUsedScorePoints={setTotalUsedScorePoints}
                          totalUsedScorePoints={totalUsedScorePoints}
                          setNewQuestion={setNewQuestion}
                          handleClickDeleteQuestion={handleClickDeleteQuestion}
                        />
                      );
                    })}
                    {provided.placeholder}
                  </div>
                )}
              </StrictModeDroppable>
            </Col>
          </Row>
        </DragDropContext>
      </Card.Body>
      <Card.Footer>
        {isCreate && (
          <>
            <Button size="sm" onClick={handleSubmit}>
              {saveQuestionsLoading && <Spinner size="sm" />}
              {!saveQuestionsLoading && t('common:button.save')}
            </Button>
            <Button
              size="sm"
              variant="danger"
              onClick={handleCancel}
              className="mx-2"
            >
              {!saveQuestionsLoading && t('common:button.cancel')}
            </Button>
          </>
        )}
        {isEdit && (
          <>
            <Button
              size="sm"
              onClick={handleSubmit}
              disabled={!!saveQuestionsLoading || examLoading}
            >
              {saveQuestionsLoading && <Spinner size="sm" />}
              {!saveQuestionsLoading && t('common:button.save')}
            </Button>

            <Button
              size="sm"
              variant="danger"
              onClick={handleCancel}
              className="mx-2"
              disabled={!!saveQuestionsLoading || examLoading}
            >
              {t('common:button.cancel')}
            </Button>
          </>
        )}
        {!isCreate && !isEdit && (
          <>
            <Button
              size="sm"
              variant="info"
              onClick={() => setEditCard('questions')}
              disabled={!!saveQuestionsLoading || examLoading}
            >
              {t('common:button.edit')}
            </Button>
          </>
        )}
      </Card.Footer>

      <QuestionModal
        t={t}
        question={newQuestion}
        currentLanguage={currentLanguage}
        totalUsedScorePoints={totalUsedScorePoints}
        handleAddQuestion={handleAddQuestion}
        handleCancel={() => setNewQuestion(null)}
      />
      <ConfirmModal {...confirmModalProps} loading={saveQuestionsLoading} />
    </Card>
  );
};

export default ExamQuestions;
