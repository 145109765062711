export const toStaticPageFormSchema = page => {
  if (!page) {
    return null;
  }
  return {
    pageTitleAr: page?.title?.ar || '',
    pageTitleEn: page?.title?.en || '',
    pageKeywordsAr: page?.keywords?.ar || '',
    pageKeywordsEn: page?.keywords?.en || '',
    pageDescriptionAr: page?.description?.ar || '',
    pageDescriptionEn: page?.description?.en || '',
    pageContentAr: page?.content?.ar || '',
    pageContentEn: page?.content?.en || ''
  };
};
