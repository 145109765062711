import StaticPagesService from 'http/StaticPagesService';
import { getErrorMessage } from 'http/utils';
import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { Card } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import LoaderWithMessage from 'components/common/LoaderWithMessage';

const PreviewStaticPage = () => {
  const { id } = useParams();
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const [page, setPage] = useState(null);

  const {
    isLoading: staticPageLoading,
    error: staticPageError,
    data: staticPageData,
    refetch: fetchStaticPage
  } = useQuery(['StaticPagePreview', id], () => StaticPagesService.get(id), {
    retry: false,
    manual: true, // Prevents automatic refetching
    refetchOnWindowFocus: false,
    enabled: false
  });

  useEffect(() => {
    if (id) {
      fetchStaticPage(id);
    }
  }, [id]);

  useEffect(() => {
    if (staticPageData && !staticPageLoading && !staticPageError) {
      setPage(staticPageData?.payload || null);
    }
    if (staticPageError) {
      toast.error(getErrorMessage(t, staticPageError), {
        theme: 'colored',
        autoClose: false
      });
    }
  }, [staticPageLoading, staticPageError, staticPageData]);

  if (staticPageLoading) {
    return (
      <LoaderWithMessage message={t('staticPages:message.loadingStaticPage')} />
    );
  }
  if (!page?.content?.ar || !page?.content?.en) {
    return '';
  }

  return (
    <Card>
      <Card.Body className="bg-light">
        <div
          dangerouslySetInnerHTML={{
            __html: page?.content?.[currentLanguage] || ''
          }}
        ></div>
      </Card.Body>
    </Card>
  );
};

export default PreviewStaticPage;
