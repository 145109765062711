import React, { useEffect, useState } from 'react';
import {
  Button,
  CloseButton,
  Col,
  Form,
  Modal,
  OverlayTrigger,
  Row,
  Tooltip
} from 'react-bootstrap';
import { toast } from 'react-toastify';
import ConfirmModal from 'components/common/ConfirmModal';
import Flex from 'components/common/Flex';
import QuestionAnswer from './QuestionAnswer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import MultiLanguageRTE from 'components/common/MultiLanguageRTE';

const initAnswer = {
  content: {
    ar: '',
    en: ''
  },
  correct: false
};

const QuestionModal = ({
  t,
  question,
  currentLanguage,
  totalUsedScorePoints,
  handleAddQuestion,
  handleCancel
}) => {
  const [questionData, setQuestionData] = useState(null);
  const [answersData, setAnswersData] = useState([]);
  const [errors, setErrors] = useState(null);
  const [confirmModalProps, setConfirmModalProps] = useState(null);
  const [hasChanges, setHasChanges] = useState(false);

  useEffect(() => {
    setQuestionData({ ...question, answers: null });
    setAnswersData(question?.answers || [initAnswer]);
  }, [question]);

  useEffect(() => {
    // validateQuestionForm(questionData);
  }, [answersData]);

  useEffect(() => {
    if (
      JSON.stringify(questionData) !==
        JSON.stringify({ ...question, answers: null }) ||
      JSON.stringify(answersData) !== JSON.stringify(question?.answers || [])
    ) {
      setHasChanges(question);
    } else {
      setHasChanges(false);
    }
    //  validateQuestionForm(questionData);
  }, [questionData]);

  const onSave = () => {
    const isValid = !validateQuestionForm(questionData);
    if (!isValid) {
      return;
    }

    handleAddQuestion({ ...questionData, answers: answersData });
  };

  const onCancel = () => {
    if (hasChanges) {
      setConfirmModalProps({
        isOpen: true,
        onClose: () => setConfirmModalProps(null),
        onConfirm: () => {
          setHasChanges(false);
          setQuestionData(null);
          setErrors(null);
          setConfirmModalProps(null);
          handleCancel();
        },
        message: t('common:message.confirmDiscardMessage'),
        header: t('common:message.confirmDiscardTitle'),
        actionButtonColor: 'danger',
        actionButtonText: t('common:button.discard')
      });
    } else {
      setHasChanges(false);
      setQuestionData(null);
      setErrors(null);
      setConfirmModalProps(null);
      handleCancel();
    }
  };

  const validateQuestionForm = data => {
    let errorObj = {};
    if (!data?.content?.ar) {
      errorObj['contentAr'] = {
        error: true,
        message: t('common:validation.fieldIsRequired')
      };
    }
    if (!data?.content?.en) {
      errorObj['contentEn'] = {
        error: true,
        message: t('common:validation.fieldIsRequired')
      };
    }
    if (!data?.type) {
      errorObj['type'] = {
        error: true,
        message: t('common:validation.fieldIsRequired')
      };
    }
    if (!data?.score && !isNaN(data?.score)) {
      errorObj['score'] = {
        error: true,
        message: t('exams:message.questionScore')
      };
    }
    if (
      !!data?.score &&
      !isNaN(data?.score) &&
      Number(data?.score) + totalUsedScorePoints > 100
    ) {
      errorObj['score'] = {
        error: true,
        message: t('exams:message.questionMaxScoreAllowed', {
          score: 100 - totalUsedScorePoints
        })
      };
    }
    if (!data?.score && isNaN(data?.score)) {
      errorObj['score'] = {
        error: true,
        message: t('common:validation.fieldIsRequired')
      };
    }
    if (!answersData?.find(a => !!a?.content?.en)) {
      if (!errorObj['answers']) {
        errorObj['answers'] = { error: true };
      }
      errorObj['answers'] = {
        ...errorObj['answers'],
        contentEn: {
          error: true,
          message: t('common:validation.fieldIsRequired')
        },
        message: t('common:validation.fieldIsRequired')
      };
    }
    if (!answersData?.find(a => !!a?.content?.ar)) {
      if (!errorObj['answers']) {
        errorObj['answers'] = { error: true };
      }
      errorObj['answers'] = {
        ...errorObj['answers'],
        contentAr: {
          error: true,
          message: t('common:validation.fieldIsRequired')
        },
        message: t('common:validation.fieldIsRequired')
      };
    }
    if (
      !answersData?.find(
        a => !!a?.content?.ar && !!a?.content?.en && !!a?.correct
      )
    ) {
      errorObj['correctAnswer'] = {
        message: t('exams:message.pleaseSelectCorrectAnswer')
      };
    }
    setErrors(errorObj);
    let hasError = Object.values(errorObj).find(err => !!err?.error);
    if (hasError) {
      toast.error(t('common:validation.pleaseFilloutRequiredFields'), {
        theme: 'colored',
        autoClose: false
      });
      console.log('new question errors', errorObj);
    } else {
      if (!!errorObj['correctAnswer']?.message) {
        hasError = true;
        toast.error(errorObj['correctAnswer']?.message, {
          theme: 'colored',
          autoClose: false
        });
      }
    }
    return hasError;
  };

  const onAnswerFocus = () => {
    if (answersData?.length > 14 && questionData?.type === 'multipleChoices') {
      return;
    }
    if (answersData?.length > 1 && questionData?.type === 'trueOrFalse') {
      return;
    }
    setAnswersData([...answersData, initAnswer]);
  };

  const onAnswerChange = (data, index) => {
    let oldAnswers = [...answersData];
    oldAnswers[index] = data;
    setAnswersData(oldAnswers);
  };

  const onAnswerRemove = index => {
    let oldAnswers = [...answersData];
    oldAnswers.splice(index, 1);
    setAnswersData(oldAnswers);
  };

  return (
    <Modal
      show={!!question}
      //onHide={onCancel}
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
    >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">
          {t('exams:labels.question', {
            name: questionData?.title?.[currentLanguage] || ''
          })}
        </Modal.Title>
        <CloseButton
          className="btn btn-circle btn-sm transition-base p-0"
          onClick={onCancel}
        />
      </Modal.Header>
      <Modal.Body>
        <Form.Group controlId="newChapter" className="position-relative">
          <Row>
            <Col md={12} className="mt-1">
              <Form.Label>
                {`${t('common:labels.type')}`}
                <span className="text-danger">*</span>
              </Form.Label>

              <Form.Select
                onChange={e => {
                  setQuestionData({
                    ...questionData,
                    type: e.target.value
                  });
                }}
                value={questionData?.type || ''}
                isInvalid={!!errors?.type?.error}
              >
                <option value="">
                  {t('course:labels.selectQuestionType')}
                </option>
                <option value="trueOrFalse">
                  {t('course:question.types.trueOrFalse')}
                </option>
                <option value="multipleChoices">
                  {t('course:question.types.multipleChoices')}
                </option>
              </Form.Select>
              <Form.Text className="text-danger">
                {!!errors?.type?.error && errors?.type?.message}
              </Form.Text>
            </Col>
            <Col md={12} className="mt-1">
              <Form.Label>
                {`${t('exams:labels.score')}`}
                <span className="text-danger">*</span>
              </Form.Label>
              <Form.Control
                type="text"
                name="score"
                required
                value={questionData?.score || ''}
                className="pe-4"
                onChange={e => {
                  if (!!e.target.value && isNaN(e.target.value)) {
                    return;
                  }
                  setQuestionData({
                    ...questionData,
                    score: Number(e.target.value)
                  });
                }}
                placeholder={t('exams:labels.score')}
                isInvalid={!!errors?.score?.error}
              />
              <Form.Text className="text-danger">
                {!!errors?.score?.error && errors?.score?.message}
              </Form.Text>
            </Col>
            <Col md={12} className="mt-1">
              <Form.Label>
                {`${t('common:labels.content')}`}
                <span className="text-danger">*</span>
              </Form.Label>

              <MultiLanguageRTE
                t={t}
                enableFullscreen
                activeLanguage={currentLanguage}
                valueEn={questionData?.content?.en || ''}
                valueAr={questionData?.content?.ar || ''}
                errorEn={!!errors?.contentEn?.error}
                errorAr={!!errors?.contentAr?.error}
                placeholderEn={t('course:placeholders.addNewItem')}
                placeholderAr={t('course:placeholders.addNewItem')}
                handleEnChange={newValue =>
                  setQuestionData({
                    ...questionData,
                    content: {
                      ...questionData?.content,
                      en: newValue
                    }
                  })
                }
                handleArChange={newValue =>
                  setQuestionData({
                    ...questionData,
                    content: {
                      ...questionData?.content,
                      ar: newValue
                    }
                  })
                }
              />
            </Col>
          </Row>
          <Row className="mt-2">
            <Col md={12} className="mt-1">
              <Form.Label>
                <Flex>
                  <h4>{`${t('exams:labels.answers')}`} </h4>
                  <span className="text-danger">*</span>
                  <OverlayTrigger
                    overlay={
                      <Tooltip id={`question-answers-count-toolip`}>
                        {t('exams:labels.addUpTo15Answer')}
                      </Tooltip>
                    }
                    className="mx-2"
                  >
                    <span style={{ height: '1.5rem' }} className="mx-2">
                      <FontAwesomeIcon icon={faExclamationCircle} />
                    </span>
                  </OverlayTrigger>
                </Flex>
              </Form.Label>
            </Col>
            {answersData?.map((answer, index) => {
              const shouldAddNewAnswer =
                index === answersData?.length - 1 ? true : false;
              return (
                <QuestionAnswer
                  key={`exam-question-answer-${index}`}
                  t={t}
                  errors={errors?.answers}
                  answer={answer}
                  currentLanguage={currentLanguage}
                  index={index}
                  onChange={onAnswerChange}
                  onRemove={onAnswerRemove}
                  onFocus={() => shouldAddNewAnswer && onAnswerFocus()}
                  correctDisabled={
                    questionData?.type === 'trueOrFalse' &&
                    !!answersData?.find(a => !!a.correct) &&
                    !answer?.correct
                  }
                />
              );
            })}
          </Row>
        </Form.Group>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={onSave} className="mt-1">
          {t('common:button.save')}
        </Button>
        <Button onClick={onCancel} variant="secondary">
          {t('common:button.cancel')}
        </Button>
      </Modal.Footer>
      <ConfirmModal {...confirmModalProps} />
    </Modal>
  );
};

export default QuestionModal;
