import React, { useState } from 'react';
import { Button, Card, Col, Image, Row } from 'react-bootstrap';
import Flex from 'components/common/Flex';
import Hoverbox from 'components/common/Hoverbox';
import SoftBadge from 'components/common/SoftBadge';
import playIcon from 'assets/img/icons/play.svg';
import { Link, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import {
  faEdit,
  faQuestionCircle,
  faStar,
  faStarHalfAlt
} from '@fortawesome/free-solid-svg-icons';
import defaultAvatar from 'assets/img/generic/image-placeholder.png';
import { toApiFileUrl, truncateString } from 'helpers/utils';
import ModalVideoContent from 'components/common/ModalVideoContent';
import ReactStars from 'react-rating-stars-component';
import { getLearningPathPricing, learningPathStatuses } from './utils';

const LearningPathListItem = ({ learningPath }) => {
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const navigate = useNavigate();
  const [showModalVideo, setShowModalVideo] = useState(false);
  const [enableHoverBox, setEnableHoverBox] = useState(true);

  const statusObj = learningPathStatuses?.find(
    s => s.name === learningPath?.status?.code
  );

  return (
    <>
      {!!enableHoverBox && (
        <ModalVideoContent
          show={showModalVideo}
          setShow={setShowModalVideo}
          attachment={{
            image: toApiFileUrl(learningPath?.image),
            src: learningPath?.promoVideo
          }}
          onVideoError={data => {
            console.log('onVideoError', data);
            setEnableHoverBox(false);
          }}
        />
      )}
      <Card className="overflow-hidden">
        <Card.Body className="p-0">
          <Row className="g-0">
            <Col md={4} lg={3}>
              <Hoverbox
                onClick={() => setShowModalVideo(true)}
                className="h-md-100"
              >
                <Button
                  variant="link"
                  onClick={() => enableHoverBox && setShowModalVideo(true)}
                  className={`p-0 border-0 h-100 d-flex align-items-center justify-content-center w-100`}
                  style={{ cursor: !enableHoverBox ? 'auto' : '' }}
                >
                  <Image
                    src={
                      learningPath?.image
                        ? toApiFileUrl(learningPath?.image)
                        : defaultAvatar
                    }
                    alt=""
                    className="w-100 h-100 fit-cover"
                    onError={({ currentTarget }) => {
                      currentTarget.onerror = null; // prevents looping
                      currentTarget.src = defaultAvatar;
                      currentTarget.style.width = '100px';
                      currentTarget.style.height = '100px';
                      currentTarget.className = '';
                    }}
                  />
                </Button>
                {!!enableHoverBox && (
                  <Hoverbox.Content className="flex-center pe-none bg-holder overlay overlay-2">
                    <Image
                      src={playIcon}
                      width={60}
                      alt=""
                      className="z-index-1"
                    />
                  </Hoverbox.Content>
                )}
              </Hoverbox>
            </Col>
            <Col md={8} lg={9} className="p-x1">
              <Row className="g-0 h-100">
                <Col lg={8} as={Flex} className="flex-column pe-x1">
                  <h4 className="mt-3 mt-sm-0 fs-0 fs-lg-1 align-middle">
                    <Link
                      to={`/learningPath/create/${learningPath?.id}`}
                      className="text-900"
                    >
                      {truncateString(
                        learningPath?.title?.[currentLanguage],
                        70
                      )}
                    </Link>
                    {learningPath?.status?.code && (
                      <SoftBadge bg={statusObj.color} className="mx-2">
                        <Flex
                          justifyContent="center"
                          alignItems="center"
                          gap="2"
                        >
                          {
                            <FontAwesomeIcon
                              icon={statusObj.icon || faQuestionCircle}
                            />
                          }
                          {t(
                            `learningPath:status.${learningPath?.status?.code}`
                          )}
                        </Flex>
                      </SoftBadge>
                    )}
                  </h4>
                  <h5 className="fs-0 text-primary">
                    {learningPath?.instructors?.map(ins => ins.name).join(',')}
                  </h5>
                  <p className="fs--1 mt-2 d-none d-lg-block">
                    {truncateString(
                      learningPath?.subtitle?.[currentLanguage],
                      100
                    )}
                  </p>
                  <p className="fs--1 mt-2 d-none d-lg-block">
                    {`${truncateString(
                      learningPath?.description?.[currentLanguage],
                      140
                    )}${learningPath?.description?.length > 140 ? '...' : ''}`}
                  </p>
                  <Flex wrap="wrap" className="gap-2 mb-3">
                    {learningPath?.settings?.tags?.map((tag, i) => (
                      <SoftBadge
                        key={`learningPath-tag-${tag}-${i}`}
                        pill
                        bg="primary"
                      >
                        {truncateString(tag, 15)}
                      </SoftBadge>
                    ))}
                  </Flex>
                  <div className="flex-1 d-flex align-items-end fw-semi-bold fs--1">
                    <span className="me-1 text-900">
                      {learningPath.stats?.rate || ''}
                    </span>
                    <ReactStars
                      count={5}
                      size={15}
                      isHalf={true}
                      value={learningPath.stats?.rate}
                      emptyIcon={
                        <FontAwesomeIcon icon={faStar} className="text-300" />
                      }
                      halfIcon={
                        <FontAwesomeIcon
                          icon={faStarHalfAlt}
                          className="text-warning"
                        />
                      }
                      fullIcon={
                        <FontAwesomeIcon
                          icon={faStar}
                          className="text-warning"
                        />
                      }
                      activeColor="#ffd700"
                      edit={false}
                      filledIcon={
                        <FontAwesomeIcon icon="star" className="text-warning" />
                      }
                    />

                    <span className="ms-2 text-secondary">
                      {(
                        learningPath.stats?.totalRates ||
                        0 ||
                        0
                      ).toLocaleString()}{' '}
                      {` ${t('learningPath:labels.reviews')}`}
                    </span>
                  </div>
                </Col>
                <Col lg={4} className="mt-4 mt-lg-0">
                  <Flex
                    justifyContent="between"
                    className="h-100 rounded border-lg border-1 flex-lg-column p-lg-3"
                  >
                    <div className="mb-lg-4 mt-auto mt-lg-0">
                      <h4 className="fs-1 text-warning d-flex align-items-center">
                        <span>
                          {getLearningPathPricing(learningPath)?.price
                            ? '$'
                            : ''}
                          {getLearningPathPricing(learningPath)?.price ||
                            t('learningPath:labels.free')}
                        </span>
                        {!!getLearningPathPricing(learningPath)?.oldPrice && (
                          <del className="ms-2 fs--1 text-700">
                            ${getLearningPathPricing(learningPath)?.oldPrice}
                          </del>
                        )}
                      </h4>
                      <p className="mb-0 fs--1 text-800">
                        {(
                          learningPath.stats?.totalStudents || 0
                        ).toLocaleString()}{' '}
                        {` ${t('learningPath:labels.students')}`}
                      </p>
                    </div>
                    <Flex className="mt-3 flex-lg-column gap-2">
                      <Button
                        size="md"
                        variant="primary"
                        className="fs--1"
                        onClick={() =>
                          navigate(`/learningPath/create/${learningPath.id}`)
                        }
                      >
                        <FontAwesomeIcon icon={faEdit} />
                        <span className="ms-1 d-none d-lg-inline">
                          {t('common:button.edit')}
                        </span>
                      </Button>
                    </Flex>
                  </Flex>
                </Col>
              </Row>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </>
  );
};

export default LearningPathListItem;
