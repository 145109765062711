import React from 'react';
import { Card, Placeholder } from 'react-bootstrap';
import classNames from 'classnames';
import { useQuery } from 'react-query';
import { useState } from 'react';
import StatisticsService from 'http/StatisticsService';
import { useEffect } from 'react';
import { toast } from 'react-toastify';
import { getErrorMessage } from 'http/utils';
import { useTranslation } from 'react-i18next';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import { Link } from 'react-router-dom';
import AdvanceTablePagination from 'components/common/advance-table/AdvanceTablePagination';
import moment from 'moment';

const defaultPaging = {
  pageNumber: 1,
  pageSize: 10
};

const defaultSorting = {
  sortBy: 'metadata.createdAt',
  sortDirection: 'DESC'
};

const Payments = ({ className }) => {
  const { t, i18n } = useTranslation();
  const isRtl = i18n.language === 'ar';
  const currentLanguage = i18n.language;
  const [payments, setPayments] = useState([]);
  const [pagingInfo, setPagingInfo] = useState(null);
  const [sortInfo, setSortInfo] = useState({ ...defaultSorting });
  const [filters, setFilters] = useState({
    ...defaultPaging,
    ...defaultSorting
  });

  const {
    isLoading: paymentsLoading,
    error: paymentsError,
    data: paymentsData
  } = useQuery(
    ['PaymentsList', { ...filters, ...sortInfo }],
    () => StatisticsService.payments({ ...filters, ...sortInfo }),
    {
      retry: false,
      manual: true, // Prevents automatic refetching
      refetchOnWindowFocus: false,
      enabled: true
    }
  );

  useEffect(() => {
    if (paymentsData && !paymentsLoading && !paymentsError) {
      setPayments(paymentsData.payload.data);
      setPagingInfo(paymentsData.payload.pagingInfo);
    }
    if (paymentsError) {
      toast.error(getErrorMessage(t, paymentsError), {
        theme: 'colored',
        autoClose: false
      });
    }
  }, [paymentsLoading, paymentsError, paymentsData]);

  const handleSort = column => {
    const direction = sortInfo.sortDirection === 'ASC' ? 'DESC' : 'ASC';
    setSortInfo({
      sortBy: column,
      sortDirection: direction
    });
  };

  const columns = [
    {
      accessor: 'title',
      Header: t('dashboard:labels.title'),
      headerProps: {
        className: 'pe-1 fw-medium',
        onClick: () => handleSort('target.title.en')
      },
      Cell: rowData => {
        const { purchaseTarget } = rowData.row.original;
        const linkTo = `/${
          purchaseTarget.type === 'learningPath' ? 'learningPath' : 'course'
        }/create/${purchaseTarget.id}`;
        return (
          <Link to={linkTo}>
            {purchaseTarget.title?.[currentLanguage] || ''}
          </Link>
        );
      }
    },
    {
      accessor: 'type',
      Header: t('dashboard:labels.type'),
      headerProps: {
        className: 'pe-1 fw-medium',
        onClick: () => handleSort('target.type')
      },
      Cell: rowData => {
        const { purchaseTarget } = rowData.row.original;
        const type = t(`dashboard:labels.${purchaseTarget.type}`);
        return <p className="mb-0">{type || ''}</p>;
      }
    },
    {
      accessor: 'user',
      Header: t('dashboard:labels.buyer'),
      headerProps: {
        className: 'pe-7 fw-medium',
        onClick: () => handleSort('user.name')
      },
      Cell: rowData => {
        const { user } = rowData.row.original;
        return <p className="mb-0">{user?.name || ''}</p>;
      }
    },
    {
      accessor: 'amount',
      Header: t('dashboard:labels.amount'),
      headerProps: {
        className: ' fw-medium',
        onClick: () => handleSort('amount')
      },

      Cell: rowData => {
        const { amount } = rowData.row.original;
        return <p className="mb-0">{amount || 0}$</p>;
      }
    },
    {
      accessor: 'createDate',
      Header: t('dashboard:labels.paymentDate'),
      headerProps: {
        className: 'fw-medium',
        onClick: () => handleSort('metadata.createdAt')
      },
      Cell: rowData => {
        const { metadata } = rowData.row.original;
        return (
          <p className="mb-0">
            {moment(metadata.createdAt).format('DD/MM/yyyy')}
          </p>
        );
      }
    }
  ];

  return (
    <Card className={classNames(className, 'overflow-hidden')}>
      <Card.Header>{t('dashboard:labels.payments')}</Card.Header>
      <Card.Body className="p-0">
        {paymentsLoading &&
          Array(5)
            .fill(1)
            .map((n, i) => {
              return (
                <Placeholder
                  key={`payments-table-skeleton-${i}`}
                  className="w-100 my-1"
                  style={{ height: '33px' }}
                  size="lg"
                />
              );
            })}
        {!paymentsLoading && (
          <AdvanceTableWrapper
            columns={columns}
            data={payments || []}
            sortable
            rowCount={pagingInfo?.totalItems}
            pagination
            perPage={10}
            manualSortBy
          >
            <AdvanceTable
              table
              headerClassName="bg-200 text-900 text-nowrap align-middle font-sans-serif"
              rowClassName="btn-reveal-trigger text-nowrap align-middle"
              tableProps={{
                className: 'fs--1 fw-semi-bold mb-0 overflow-hidden'
              }}
            />
          </AdvanceTableWrapper>
        )}
      </Card.Body>
      <Card.Footer>
        <AdvanceTablePagination
          table
          pageCount={pagingInfo?.totalPages || 0}
          pageIndex={pagingInfo?.currentPage || 0}
          gotoPage={page => {
            setFilters({ ...filters, pageNumber: page });
          }}
          isRtl={isRtl}
        />
      </Card.Footer>
    </Card>
  );
};

export default Payments;
