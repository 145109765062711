import http from 'http/interceptor';
import { getItemFromStore } from 'helpers/utils';
const currentLocale =
  getItemFromStore('preferredLanguage', 'en') ||
  process.env.REACT_APP_DEFAULT_LANGUAGE;
const BASE_API_URL = `${process.env.REACT_APP_API_BASE_URL}/api/${currentLocale}/${process.env.REACT_APP_API_VERSION}`;
const LIST_STATIC_PAGES_URL = `${BASE_API_URL}/staticPages/list`;
const SAVE_STATIC_PAGES_URL = `${BASE_API_URL}/staticPages/create`;
const GET_STATIC_PAGES_URL = `${BASE_API_URL}/staticPages/get`;
const DELETE_STATIC_PAGES_URL = `${BASE_API_URL}/staticPages/delete`;

const StaticPagesService = {
  list: data => {
    return http.post(
      LIST_STATIC_PAGES_URL,
      { ...data },
      {
        headers: { 'Content-Type': 'Application/Json' }
      }
    );
  },
  get: id => {
    return http.get(`${GET_STATIC_PAGES_URL}?id=${id}`, {
      headers: { 'Content-Type': 'Application/Json' }
    });
  },
  save: data => {
    return http.post(
      SAVE_STATIC_PAGES_URL,
      { ...data },
      {
        headers: { 'Content-Type': 'Application/Json' }
      }
    );
  },
  delete: data => {
    return http.post(`${DELETE_STATIC_PAGES_URL}?id=${data}`, {
      headers: { 'Content-Type': 'Application/Json' }
    });
  }
};

export default StaticPagesService;
