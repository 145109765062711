import http from 'http/interceptor';
import { getItemFromStore } from 'helpers/utils';
const currentLocale =
  getItemFromStore('preferredLanguage', 'en') ||
  process.env.REACT_APP_DEFAULT_LANGUAGE;
const BASE_API_URL = `${process.env.REACT_APP_API_BASE_URL}/api/${currentLocale}/${process.env.REACT_APP_API_VERSION}`;
const LIST_BLOGS_URL = `${BASE_API_URL}/blogs/list`;
const SAVE_BLOGS_URL = `${BASE_API_URL}/blogs/create`;
const GET_BLOGS_URL = `${BASE_API_URL}/blogs/get`;
const DELETE_BLOGS_URL = `${BASE_API_URL}/blogs/delete`;
const UPDATE_BLOG_WRITER_URL = `${BASE_API_URL}/blogs/writer/update`;
const UPDATE_BLOG_REFERENCES_URL = `${BASE_API_URL}/blogs/references/update`;
const UPLOAD_BLOG_IMAGE_URL = `${BASE_API_URL}/blogs/UploadBlogImage`;
const UPDATE_BLOG_STATUS_URL = `${BASE_API_URL}/blogs/UpdateBlogStatus`;

const BlogsService = {
  list: data => {
    return http.post(
      LIST_BLOGS_URL,
      { ...data },
      {
        headers: { 'Content-Type': 'Application/Json' }
      }
    );
  },
  get: id => {
    return http.get(`${GET_BLOGS_URL}?id=${id}`, {
      headers: { 'Content-Type': 'Application/Json' }
    });
  },
  save: data => {
    return http.post(
      SAVE_BLOGS_URL,
      { ...data },
      {
        headers: { 'Content-Type': 'Application/Json' }
      }
    );
  },
  delete: data => {
    return http.post(`${DELETE_BLOGS_URL}?id=${data}`, {
      headers: { 'Content-Type': 'Application/Json' }
    });
  },
  updateWriter: data => {
    return http.post(
      UPDATE_BLOG_WRITER_URL,
      { ...data },
      {
        headers: { 'Content-Type': 'Application/Json' }
      }
    );
  },
  uploadImage: data => {
    const url = `${UPLOAD_BLOG_IMAGE_URL}`;
    return http.post(url, data, {
      headers: { 'Content-Type': 'multipart/form-data' }
    });
  },
  updateReferences: data => {
    return http.post(
      UPDATE_BLOG_REFERENCES_URL,
      { ...data },
      {
        headers: { 'Content-Type': 'Application/Json' }
      }
    );
  },
  updateStatus: data => {
    return http.post(
      UPDATE_BLOG_STATUS_URL,
      { ...data },
      {
        headers: { 'Content-Type': 'Application/Json' }
      }
    );
  }
};

export default BlogsService;
