import React from 'react';
import { Button, Card, Col, Form, Row, Spinner } from 'react-bootstrap';

import { useState } from 'react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { getErrorMessage } from 'http/utils';
import LoaderWithMessage from 'components/common/LoaderWithMessage';
import ConfirmModal from 'components/common/ConfirmModal';
import ExamService from 'http/ExamService';
import AsyncSelect from 'components/common/AsyncSelect';
import MultiSelect from 'components/common/MultiSelect';
import { useContext } from 'react';
import { ExamContext } from 'context/Context';
import SelectComponent from 'components/common/SelectComponent';
import { toExamFormSchema } from '../utils';

const levels = [
  { label: 'starter', value: 'starter' },
  { label: 'intermediate', value: 'intermediate' },
  { label: 'advanced', value: 'advanced' },
  { label: 'professional', value: 'professional' }
];

const initExam = {
  title: {
    ar: '',
    en: ''
  },
  category: null,
  course: null,
  level: null,
  tags: [],
  duration: null,
  attemptsAllowed: null,
  retryPeriod: null,
  passingScore: null
};

const optionalFields = [
  'examDescriptionAr',
  'examDescriptionEn',
  'tags',
  'retryPeriod',
  'attemptsAllowed',
  'category'
];

const ExamBasicForm = () => {
  const { id } = useParams();
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const currentLanguage = i18n.language;
  const { exam, examLoading, categories, editCard, setEditCard, fetchExam } =
    useContext(ExamContext);
  const [examValues, setExamValues] = useState(null);
  const [courses, setCourses] = useState([]);
  const [formKey, setFormKey] = useState(Date.now()); // Create a state variable to force re-render
  const [errors, setErrors] = useState(null);
  const [confirmModalProps, setConfirmModalProps] = useState(null);

  const {
    isLoading: coursesLoading,
    data: coursesData,
    error: coursesError
  } = useQuery(
    'CreateExamCourses',
    () =>
      ExamService.listCourses({
        pageNumber: 1,
        pageSize: 10,
        query: {
          term: '',
          conjunction: 'or',
          properties: [
            'title.ar',
            'title.en',
            'subtitle.ar',
            'subtitle.en',
            'description.ar',
            'description.en'
          ]
        }
      }),
    {
      retry: false,
      manual: true, // Prevents automatic refetching
      refetchOnWindowFocus: false,
      enabled: false
    }
  );

  useEffect(() => {
    if (exam?.id) {
      fillInitialData(exam || null);
    } else {
      fillInitialData(initExam);
    }
  }, [exam]);

  const {
    mutate: createExam,
    error: examCreateError,
    isLoading: examCreateLoading
  } = useMutation({
    mutationFn: ExamService.create,
    onSuccess: data => onCreateExamSuccess(data)
  });

  useEffect(() => {
    if (coursesData && !coursesLoading && !coursesError) {
      if (coursesData?.errors?.length) {
        toast.error(getErrorMessage(t, coursesData.errors), {
          theme: 'colored',
          autoClose: false
        });
      }

      setCourses(coursesData.payload.data);
    }

    if (coursesError) {
      toast.error(getErrorMessage(t, coursesError), {
        theme: 'colored',
        autoClose: false
      });
    }
  }, [coursesLoading, coursesData, coursesError]);

  const fillInitialData = data => {
    if (!data) {
      return;
    }
    const formattedData = toExamFormSchema(data);
    //console.log('formattedData', formattedData);
    setExamValues(formattedData);
    setFormKey(Date.now());
  };

  const asyncCoursesRequest = searchTerm => {
    return new Promise(resolve => {
      ExamService.listCourses({
        pageNumber: 1,
        pageSize: 10,
        query: {
          term: searchTerm,
          conjunction: 'or',
          properties: [
            'title.ar',
            'title.en',
            'subtitle.ar',
            'subtitle.en',
            'description.ar',
            'description.en'
          ]
        }
      })
        .then(response => {
          //console.log('response', response);
          if (response?.payload?.data) {
            resolve(response.payload.data);
          } else {
            resolve([]);
          }
        })
        .catch(error => {
          resolve([]);
          toast.error(getErrorMessage(t, error), {
            theme: 'colored',
            autoClose: false
          });
        });
    });
  };

  const onSubmit = () => {
    const isValid = validateForm(examValues);
    if (!isValid) {
      toast.error(t('common:validation.pleaseFilloutRequiredFields'), {
        theme: 'colored',
        autoClose: false
      });
      return;
    }
    const values = { ...examValues };
    let result = {};
    Object.entries(values).map(([key, value]) => {
      const isValid = !!value;
      result[key] = isValid;
    });
    let createPayload = {
      title: {
        ar: values.examTitleAr,
        en: values.examTitleEn
      },
      description: values.examDescriptionAr
        ? {
            ar: values.examDescriptionAr,
            en: values.examDescriptionEn
          }
        : null,
      category: values.category.id,
      level: values?.level,
      retryPeriod: values?.retryPeriod || null,
      attemptsAllowed: Number(values?.attemptsAllowed),
      passingScore: values?.passingScore,
      courseId: values?.course.id,
      tags: values?.tags?.length ? values?.tags.map(t => t.value) : null,
      duration: values?.duration
    };

    if (exam?.id) {
      createPayload['id'] = exam?.id;
    }
    createExam(createPayload);
  };

  const onCreateExamSuccess = response => {
    toast.success(
      t(`common:message.${!exam?.id ? 'createSuccess' : 'updateSuccess'}`),
      { theme: 'colored' }
    );
    if (typeof response?.payload === 'string' && !exam?.id) {
      navigate(`/exams/create/${response.payload}`);
    } else {
      setEditCard(null);
      fetchExam(exam.id);
    }
  };

  const validateForm = values => {
    let valid = true;
    let errors = {};
    if (!values) {
      toast.error(t('common:validation.pleaseFilloutRequiredFields'), {
        theme: 'colored',
        autoClose: false
      });
      return false;
    }
    Object.entries(values).map(([key, value]) => {
      if (key === 'examTitleAr') {
        if (value?.length > 60) {
          errors['examTitleAr'] = {
            error: true,
            message: t('course:validation.message.maxAllowedCharacters', {
              count: 60
            })
          };
        }
      }
      if (key === 'examTitleEn') {
        if (value?.length > 60) {
          errors['examTitleEn'] = {
            error: true,
            message: t('course:validation.message.maxAllowedCharacters', {
              count: 60
            })
          };
        }
      }
      if (!value && !optionalFields.includes(key)) {
        errors[key] = {
          error: true,
          message: t('common:validation.fieldIsRequired')
        };
        valid = false;
      }
    });

    setErrors(errors);
    console.log('onError', errors);
    return valid;
  };

  const handleChange = e => {
    const { name, value } = e.target;
    const dataType =
      e.target.getAttribute && e?.target?.getAttribute('data-type');
    if (dataType === 'number') {
      if (!!value && isNaN(value)) {
        return;
      }
    }

    if (!!value) {
      setErrors({ ...errors, [name]: { error: false, message: '' } });
    }
    setExamValues({ ...examValues, [name]: value });
  };

  const onCancel = () => {
    if (editCard === 'basicInfo') {
      fillInitialData(exam);
      setEditCard(null);
      return;
    }

    const hasChanges =
      JSON.stringify({ ...toExamFormSchema(exam) }) !==
      JSON.stringify({ ...examValues });
    if (hasChanges) {
      setConfirmModalProps({
        isOpen: true,
        onClose: () => setConfirmModalProps(null),
        onConfirm: () => navigate('/exams'),
        message: t('exams:message.discardExamConfirm'),
        header: t('exams:message.discardExamTitle'),
        actionButtonColor: 'danger',
        actionButtonText: t('common:button.discard')
      });
      return;
    }
    navigate('/contents/exams');
  };

  useEffect(() => {
    if (examCreateError) {
      toast.error(getErrorMessage(t, examCreateError), {
        theme: 'colored',
        autoClose: false
      });
    }
  }, [examCreateError]);

  if (examLoading) {
    return <LoaderWithMessage message={t('exams:message.loadingExam')} />;
  }

  return (
    <Card className="mb-3">
      <Card.Header as="h5">
        {!!id ? exam?.title?.[currentLanguage] : t('exams:labels.newExam')}
      </Card.Header>
      <Card.Body className="bg-light">
        <Row className="gx-2 gy-3" key={formKey}>
          <Col md="6">
            <Form.Group controlId="examTitleAr">
              <Form.Label>
                {`${t('exams:labels.title')} (${t('common:language.ar.name')})`}
                <span className="text-danger">*</span>
                <small>
                  {`(${t('common:labels.maxCharacters', { count: 60 })})`}
                </small>
              </Form.Label>
              <Form.Control
                type="text"
                name="examTitleAr"
                required
                placeholder={t('exams:labels.title')}
                onChange={handleChange}
                value={examValues?.examTitleAr || ''}
                disabled={
                  examCreateLoading ||
                  examLoading ||
                  (!!id && editCard !== 'basicInfo')
                }
                isInvalid={!!errors?.examTitleAr?.error}
              />
            </Form.Group>
            <Form.Text className="text-danger">
              {!!errors?.examTitleAr?.error && errors?.examTitleAr?.message}
            </Form.Text>
          </Col>
          <Col md="6">
            <Form.Group controlId="examTitleEn">
              <Form.Label>
                {`${t('exams:labels.title')} (${t('common:language.en.name')})`}
                <span className="text-danger">*</span>
                <small>
                  {`(${t('common:labels.maxCharacters', { count: 60 })})`}
                </small>
              </Form.Label>
              <Form.Control
                type="text"
                name="examTitleEn"
                required
                placeholder={t('exams:labels.title')}
                onChange={handleChange}
                value={examValues?.examTitleEn || ''}
                disabled={
                  examCreateLoading ||
                  examLoading ||
                  (!!id && editCard !== 'basicInfo')
                }
                isInvalid={!!errors?.examTitleEn?.error}
              />
              <Form.Text className="text-danger">
                {!!errors?.examTitleEn?.error && errors?.examTitleEn?.message}
              </Form.Text>
            </Form.Group>
          </Col>
          <Col md="6">
            <Form.Group controlId="examCategory">
              <Form.Label>{`${t('exams:labels.category')}`}</Form.Label>
              <SelectComponent
                options={categories}
                onChange={option =>
                  handleChange({
                    target: {
                      value: option?.value
                        ? { id: option.value, title: option.label }
                        : null,
                      name: 'category'
                    }
                  })
                }
                value={
                  categories?.find(c => c.value === examValues?.category?.id) ||
                  ''
                }
                placeholder={t('exams:labels.category')}
                invalid={!!errors?.category?.error}
                isDisabled={
                  examCreateLoading ||
                  examLoading ||
                  (!!id && editCard !== 'basicInfo')
                }
                isClearable
              />
              <Form.Text className="text-danger">
                {!!errors?.category?.error && errors?.category?.message}
              </Form.Text>
            </Form.Group>
          </Col>
          <Col md="6">
            <Form.Group controlId="examCourse">
              <Form.Label>
                {`${t('exams:labels.course')}`}
                <span className="text-danger">*</span>
              </Form.Label>
              <AsyncSelect
                asyncRequest={asyncCoursesRequest}
                defaultOptions={courses}
                placeholder={t('learningPath:placeholders.selectCourse')}
                classNamePrefix="react-select"
                isLoading={coursesLoading}
                value={examValues?.course}
                onChange={option =>
                  handleChange({ target: { value: option, name: 'course' } })
                }
                getOptionLabel={option => option.title?.[currentLanguage]}
                getOptionValue={option => option.id}
                noOptionsMessage={() =>
                  !courses?.length
                    ? undefined
                    : t('learningPath:placeholders.selectCourse')
                }
                isDisabled={
                  examCreateLoading ||
                  examLoading ||
                  (!!id && editCard !== 'basicInfo') ||
                  coursesLoading
                }
                invalid={!!errors?.course?.error}
              />
              <Form.Text className="text-danger">
                {!!errors?.course?.error && errors?.course?.message}
              </Form.Text>
            </Form.Group>
          </Col>
          <Col md="6">
            <Form.Group controlId="examTagsEn">
              <Form.Label>{`${t('exams:labels.tags')}`}</Form.Label>
              <MultiSelect
                closeMenuOnSelect={true}
                openMenuOnClick={false}
                isMulti
                options={[]}
                placeholder={t('course:placeholders.selectTag')}
                isDisabled={
                  examCreateLoading ||
                  examLoading ||
                  (!!id && editCard !== 'basicInfo')
                }
                isCreatable={true}
                createLabel={value => t('course:labels.addTag') + ' ' + value}
                isInvalid={!!errors?.tags?.error}
                onChange={val =>
                  handleChange({ target: { value: val, name: 'tags' } })
                }
                value={examValues?.tags}
              />
              <Form.Text className="text-danger">
                {!!errors?.tags?.error && errors?.tags?.message}
              </Form.Text>
            </Form.Group>
          </Col>
          <Col md="6">
            <Row>
              <Col md="6">
                <Form.Group controlId="examDuration">
                  <Form.Label>
                    {`${t('exams:labels.duration')}`}
                    <span className="text-danger">*</span>
                    <small>{`(${t(
                      'common:labels.durationSymbols.minute'
                    )})`}</small>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="duration"
                    required
                    placeholder={t('exams:labels.duration')}
                    data-type="number"
                    onChange={handleChange}
                    value={examValues?.duration || ''}
                    disabled={
                      examCreateLoading ||
                      examLoading ||
                      (!!id && editCard !== 'basicInfo')
                    }
                    isInvalid={!!errors?.duration?.error}
                  />
                  <Form.Text className="text-danger">
                    {!!errors?.duration?.error && errors?.duration?.message}
                  </Form.Text>
                </Form.Group>
              </Col>
              <Col md="6">
                <Form.Group controlId="examAttemptsAllowed">
                  <Form.Label>
                    {`${t('exams:labels.attemptsAllowed')}`}
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="attemptsAllowed"
                    required
                    placeholder={t('exams:labels.attemptsAllowed')}
                    data-type="number"
                    onChange={handleChange}
                    value={examValues?.attemptsAllowed || ''}
                    disabled={
                      examCreateLoading ||
                      examLoading ||
                      (!!id && editCard !== 'basicInfo')
                    }
                    isInvalid={!!errors?.attemptsAllowed?.error}
                  />
                  <Form.Text className="text-danger">
                    {!!errors?.attemptsAllowed?.error &&
                      errors?.attemptsAllowed?.message}
                  </Form.Text>
                </Form.Group>
              </Col>
            </Row>
          </Col>
          <Col md="6">
            <Form.Group controlId="examLevel">
              <Form.Label>
                {`${t('exams:labels.level')}`}
                <span className="text-danger">*</span>
              </Form.Label>
              <SelectComponent
                options={levels}
                onChange={option =>
                  handleChange({
                    target: { value: option?.value || null, name: 'level' }
                  })
                }
                value={levels?.find(c => c.value === examValues?.level) || null}
                placeholder={t('exams:labels.level')}
                getOptionLabel={option => t(`course:level.${option.label}`)}
                invalid={!!errors?.level?.error}
                isClearable
                isDisabled={
                  examCreateLoading ||
                  examLoading ||
                  (!!id && editCard !== 'basicInfo')
                }
              />
              <Form.Text className="text-danger">
                {!!errors?.level?.error && errors?.level?.message}
              </Form.Text>
            </Form.Group>
          </Col>
          <Col md="6">
            <Row>
              <Col md="6">
                <Form.Group controlId="examPassingScore">
                  <Form.Label>
                    {`${t('exams:labels.passingScore')}`}
                    <span className="text-danger">*</span>
                    <small>{`(${t('common:labels.maxNumber', {
                      count: 100
                    })})`}</small>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="passingScore"
                    required
                    placeholder={t('exams:labels.passingScore')}
                    data-type="number"
                    onChange={handleChange}
                    value={examValues?.passingScore || ''}
                    disabled={
                      examCreateLoading ||
                      examLoading ||
                      (!!id && editCard !== 'basicInfo')
                    }
                    isInvalid={!!errors?.passingScore?.error}
                  />
                  <Form.Text className="text-danger">
                    {!!errors?.passingScore?.error &&
                      errors?.passingScore?.message}
                  </Form.Text>
                </Form.Group>
              </Col>
              <Col md="6">
                <Form.Group controlId="examRetryPeriod">
                  <Form.Label>
                    {`${t('exams:labels.retryPeriod')} ${t(
                      'common:labels.days'
                    )}`}
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="retryPeriod"
                    required
                    placeholder={t('exams:labels.retryPeriod')}
                    data-type="number"
                    onChange={handleChange}
                    value={examValues?.retryPeriod || ''}
                    disabled={
                      examCreateLoading ||
                      examLoading ||
                      (!!id && editCard !== 'basicInfo')
                    }
                    isInvalid={!!errors?.retryPeriod?.error}
                  />
                  <Form.Text className="text-danger">
                    {!!errors?.retryPeriod?.error &&
                      errors?.retryPeriod?.message}
                  </Form.Text>
                </Form.Group>
              </Col>
            </Row>
          </Col>
          <Col md="6">
            <Form.Group controlId="examDescriptionAr">
              <Form.Label>
                {`${t('exams:labels.description')} (${t(
                  'common:language.ar.name'
                )})`}
                <span className="text-danger">*</span>
                <small>
                  {`(${t('common:labels.maxCharacters', { count: 150 })})`}
                </small>
              </Form.Label>
              <Form.Control
                as="textarea"
                name="examDescriptionAr"
                required
                placeholder={t('exams:labels.description')}
                onChange={handleChange}
                value={examValues?.examDescriptionAr || ''}
                disabled={
                  examCreateLoading ||
                  examLoading ||
                  (!!id && editCard !== 'basicInfo')
                }
              />
            </Form.Group>
          </Col>
          <Col md="6">
            <Form.Group controlId="examDescriptionEn">
              <Form.Label>
                {`${t('exams:labels.description')} (${t(
                  'common:language.en.name'
                )})`}
                <span className="text-danger">*</span>
                <small>
                  {`(${t('common:labels.maxCharacters', { count: 150 })})`}
                </small>
              </Form.Label>
              <Form.Control
                as="textarea"
                name="examDescriptionEn"
                required
                placeholder={t('exams:labels.description')}
                onChange={handleChange}
                value={examValues?.examDescriptionEn || ''}
                disabled={
                  examCreateLoading ||
                  examLoading ||
                  (!!id && editCard !== 'basicInfo')
                }
              />
            </Form.Group>
          </Col>
        </Row>
      </Card.Body>

      <Card.Footer>
        {!id && (
          <>
            <Button size="sm" onClick={onSubmit}>
              {examCreateLoading && <Spinner size="sm" />}
              {!examCreateLoading && t('common:button.save')}
            </Button>
            <Button
              size="sm"
              variant="danger"
              onClick={onCancel}
              className="mx-2"
            >
              {!examCreateLoading && t('common:button.cancel')}
            </Button>
          </>
        )}
        {editCard === 'basicInfo' && !!id && (
          <>
            <Button
              size="sm"
              onClick={onSubmit}
              disabled={!!examCreateLoading || examLoading}
            >
              {examCreateLoading && <Spinner size="sm" />}
              {!examCreateLoading && t('common:button.save')}
            </Button>

            <Button
              size="sm"
              variant="danger"
              onClick={onCancel}
              className="mx-2"
              disabled={!!examCreateLoading || examLoading}
            >
              {t('common:button.cancel')}
            </Button>
          </>
        )}
        {editCard !== 'basicInfo' && !!id && (
          <>
            <Button
              size="sm"
              variant="info"
              onClick={() => setEditCard('basicInfo')}
              disabled={!!examCreateLoading || examLoading}
            >
              {t('common:button.edit')}
            </Button>
          </>
        )}
      </Card.Footer>
      {!!confirmModalProps && (
        <ConfirmModal {...confirmModalProps} loading={false} />
      )}
    </Card>
  );
};
export default ExamBasicForm;
