import React, { useState } from 'react';
import PropTypes from 'prop-types';
import SimpleBarReact from 'simplebar-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Collapse, Form, Image, Card } from 'react-bootstrap';
import { slugifyText } from 'helpers/utils';
import Flex from 'components/common/Flex';
import SoftBadge from 'components/common/SoftBadge';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import LearningPathService from 'http/LearningPathService';
import { useEffect } from 'react';
import { toast } from 'react-toastify';
import { getErrorMessage } from 'http/utils';
import { faChevronUp, faStar } from '@fortawesome/free-solid-svg-icons';
import doubleChevronUp from 'assets/img/icons/double-chevron-up.svg';
import tripleChevronUp from 'assets/img/icons/triple-chevron-up.svg';

const LearningPathFilters = ({ setShow, isOffcanvas, handleFilter }) => {
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const [filters, setFilters] = useState([]);
  const [filterOptions, setFilterOptions] = useState([]);
  let learningPathFilters = [
    {
      label: 'status',
      options: [
        {
          label: t('learningPath:status.published'),
          icon: 'file-alt',
          type: 'checkbox',
          value: 'published'
        },
        {
          label: t('learningPath:status.approved'),
          icon: 'file-invoice-dollar',
          type: 'checkbox',
          value: 'approved'
        },
        {
          label: t('learningPath:status.rejected'),
          icon: 'file-invoice-dollar',
          type: 'checkbox',
          value: 'rejected'
        },
        {
          label: t('learningPath:status.onReview'),
          icon: 'file-invoice-dollar',
          type: 'checkbox',
          value: 'onReview'
        },
        {
          label: t('learningPath:status.modificationsRequested'),
          icon: 'file-invoice-dollar',
          type: 'checkbox',
          value: 'modificationsRequested'
        },
        {
          label: t('learningPath:status.draft'),
          icon: 'file-invoice-dollar',
          type: 'checkbox',
          value: 'draft'
        }
      ]
    },
    {
      label: 'pricing',
      options: [
        {
          label: t('common:labels.free'),
          icon: 'file-alt',
          type: 'radio',
          value: 'free',
          name: 'pricing'
        },
        {
          label: t('common:labels.paid'),
          icon: 'file-invoice-dollar',
          type: 'radio',
          value: 'paid',
          name: 'pricing'
        }
      ]
    },
    {
      label: 'category',
      options: null
    },
    {
      label: 'rating',
      options: [
        {
          label: `4.5 ${t('common:labels.&Up')}`,
          icon: 'star',
          type: 'radio',
          name: 'rating',
          value: 4.5
        },
        {
          label: `4.0 ${t('common:labels.&Up')}`,
          icon: 'star',
          type: 'radio',
          name: 'rating',
          value: 4.0
        },
        {
          label: `3.0 ${t('common:labels.&Up')}`,
          icon: 'star',
          type: 'radio',
          name: 'rating',
          value: 3.0
        },
        {
          label: `2.0 ${t('common:labels.&Up')}`,
          icon: 'star',
          type: 'radio',
          name: 'rating',
          value: 2.0
        }
      ]
    },
    {
      label: 'level',
      options: [
        {
          label: t('learningPath:level.starter'),
          icon: faChevronUp,
          type: 'checkbox',
          value: 'starter',
          name: t('learningPath:level.starter')
        },
        {
          label: t('learningPath:level.intermediate'),
          svg: doubleChevronUp,
          type: 'checkbox',
          value: 'intermediate',
          name: t('learningPath:level.intermediate')
        },
        {
          label: t('learningPath:level.advanced'),
          svg: tripleChevronUp,
          type: 'checkbox',
          value: 'advanced',
          name: t('learningPath:level.advanced')
        },
        {
          label: t('learningPath:level.professional'),
          icon: faStar,
          type: 'checkbox',
          value: 'professional',
          name: t('learningPath:level.professional')
        }
      ]
    },
    {
      label: 'language',
      options: [
        {
          label: t('common:languages.arabic'),
          type: 'checkbox',
          value: 'ar',
          name: t('common:languages.arabic')
        },
        {
          label: t('common:languages.english'),
          type: 'checkbox',
          value: 'en',
          name: t('common:languages.english')
        }
      ]
    }
  ];
  const {
    isLoading: categoriesLoading,
    data: categoriesData,
    error: categoriesError
  } = useQuery(
    'CategoriesData',
    () => LearningPathService.listCategories({ pageNumber: 1, pageSize: 1000 }),
    {
      retry: false,
      manual: true, // Prevents automatic refetching
      refetchOnWindowFocus: false
    }
  );

  const formatFilters = data => {
    //console.log('data', { data });
    let filters = {};
    data.map(filter => {
      if (filter?.type !== 'radio') {
        if (!!filters[filter.filterName]) {
          if (Array.isArray(filters[filter.filterName])) {
            filters[filter.filterName] = [
              filter.value,
              ...filters[filter.filterName]
            ];
          } else {
            filters[filter.filterName] = [
              filter.value,
              filters[filter.filterName]
            ];
          }
        } else {
          filters[filter.filterName] = [filter.value];
        }
      } else {
        filters[filter.filterName] = filter.value;
      }
      return false;
    });
    //console.log('filters', filters);
    return filters;
  };

  useEffect(() => {
    if (categoriesData && !categoriesLoading && !categoriesError) {
      const categoriesResponse = categoriesData?.payload?.data || [];
      const filtersData = learningPathFilters.map(f => {
        if (f.label === 'category') {
          return {
            ...f,
            options: [
              ...categoriesResponse.map(category => {
                return {
                  label: category.title[currentLanguage],
                  //icon: 'globe',
                  type: 'checkbox',
                  value: category.id,
                  name: category.title[currentLanguage]
                };
              })
            ]
          };
        }
        return { ...f };
      });
      //console.log('filters', filtersData);
      setFilters(filtersData);
    }

    if (categoriesError) {
      setFilters(learningPathFilters);
      toast.error(getErrorMessage(t, categoriesError), {
        theme: 'colored',
        autoClose: false
      });
    }
  }, [categoriesLoading, categoriesData, categoriesError]);

  const handleFilterOptions = e => {
    let newData = [];
    const { type, name, value, checked } = e.target;
    const label = e.label;
    const filterName = e.filterName;
    if (type === 'checkbox') {
      let options = [...filterOptions];
      options = options.filter(option => option.value !== value);

      checked && options.push({ name, value, type, label, filterName });
      newData = options;
    }

    if (type === 'radio') {
      const isExists = filterOptions.some(el => el.name === name);
      isExists
        ? (newData = filterOptions.map(el =>
            el.name === name ? { ...el, value, type, label, filterName } : el
          ))
        : (newData = [...filterOptions, { name, value, label, filterName }]);
    }

    let filters = formatFilters(newData, type);
    if (!!filters?.pricing) {
      if (filters?.pricing === 'paid') {
        filters['pricingGreater'] = 0;
      } else {
        filters['pricingLess'] = 0;
      }
      delete filters['pricing'];
    }
    if (filters?.rating) {
      filters.rating = parseFloat(filters?.rating);
    }
    //console.log('filters', filters);

    handleFilter(filters);

    setFilterOptions(newData);
  };

  return (
    <Card className="learningPath-filter">
      <SimpleBarReact style={{ height: '100%' }}>
        <Card.Header as={Flex} className="flex-between-center pt-x1">
          <Flex className="gap-2 flex-xl-grow-1 align-items-center justify-content-xl-between">
            <h5 className="mb-0 text-700 fs-0 d-flex align-items-center">
              <FontAwesomeIcon icon="filter" className="fs--1 me-1" />
              <span>{t('common:labels.filters')}</span>
            </h5>
            <Button
              variant="outline-secondary"
              size="sm"
              className="ms-2 mt-0 mb-0"
              style={{ fontSize: '12px' }}
              onClick={() => {
                setFilterOptions([]);
                handleFilter(null);
              }}
            >
              <FontAwesomeIcon icon="redo-alt" className="me-1 fs--2" />
              {t('common:labels.reset')}
            </Button>
          </Flex>
          {isOffcanvas && (
            <Button
              onClick={() => setShow(false)}
              className="btn-close text-reset"
              size="sm"
              variant="link"
            ></Button>
          )}
        </Card.Header>
        <Card.Body className="py-0">
          {filterOptions.length > 0 && (
            <Flex wrap="wrap" className="gap-2 mb-3">
              {filterOptions.map(tag => (
                <SoftBadge
                  key={tag.value}
                  className="text-capitalize bg-300 text-700 py-0"
                >
                  {tag.label}
                  <Button
                    size="sm"
                    variant="link"
                    className="p-0 text-700"
                    onClick={() => {
                      const newData = filterOptions.filter(
                        ({ value }) => value !== tag.value
                      );
                      const filters = formatFilters(newData, null);
                      setFilterOptions(newData);
                      handleFilter(filters);
                    }}
                  >
                    <FontAwesomeIcon icon="times" className="ms-1 fs--2" />
                  </Button>
                </SoftBadge>
              ))}
            </Flex>
          )}
          <ul className="list-unstyled">
            {filters.map((filter, index) => (
              <FilterItem
                key={`${slugifyText(filter.label)}-${index}`}
                index={index}
                filter={filter}
                filterOptions={filterOptions}
                setFilterOptions={setFilterOptions}
                handleFilterOptions={handleFilterOptions}
                t={t}
              />
            ))}
          </ul>
        </Card.Body>
      </SimpleBarReact>
    </Card>
  );
};

LearningPathFilters.propTypes = {
  setShow: PropTypes.func,
  isOffcanvas: PropTypes.bool
};

const FilterItem = ({
  filter,
  index,
  filterOptions,
  handleFilterOptions,
  totalFilters,
  t
}) => {
  const [open, setOpen] = useState(false);

  return (
    <li className={`${totalFilters - 1 !== index && 'border-bottom'}`}>
      <Button
        variant="link"
        onClick={() => setOpen(!open)}
        aria-controls={`${slugifyText(filter.label)}-collapse`}
        aria-expanded={index === 0 || index === 1 ? !open : open}
        className="collapse-indicator-plus w-100 fs--3 fw-large text-start text-700 text-decoration-none py-3 px-0"
      >
        {t(`learningPath:filters.${filter.label}`)}
      </Button>
      <Collapse
        in={index === 0 || index === 1 ? !open : open}
        id={`${slugifyText(filter.label)}-collapse`}
      >
        <ul className="list-unstyled">
          {filter.options &&
            filter.options.map(option => (
              <li key={slugifyText(option.label)}>
                <Form.Check
                  type={option.type}
                  className="form-check d-flex ps-0"
                >
                  <Form.Check.Label
                    className="fs--1 flex-1 text-truncate"
                    htmlFor={`filter-${slugifyText(filter.label)}-${slugifyText(
                      option.label
                    )}`}
                  >
                    {option.icon && (
                      <FontAwesomeIcon
                        icon={option.icon}
                        className={`me-3 ${
                          option.iconShrink ? 'fs--2' : 'fs--1'
                        }`}
                      />
                    )}
                    {option.svg && (
                      <Image
                        src={option.svg}
                        width={13}
                        alt=""
                        className="me-3"
                      />
                    )}
                    {option.label}
                  </Form.Check.Label>

                  <Form.Check.Input
                    type={option.type}
                    onChange={e =>
                      handleFilterOptions({
                        ...e,
                        label: option.label,
                        filterName: filter.label
                      })
                    }
                    checked={filterOptions.some(
                      ({ value }) => option.value === value
                    )}
                    id={`filter-${slugifyText(filter.label)}-${slugifyText(
                      option.label
                    )}`}
                    name={option.name}
                    value={option.value}
                  />
                </Form.Check>
              </li>
            ))}
        </ul>
      </Collapse>
    </li>
  );
};

FilterItem.propTypes = {
  index: PropTypes.number,
  filter: PropTypes.shape({
    label: PropTypes.string,
    options: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        name: PropTypes.string,
        type: PropTypes.string,
        value: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
      })
    )
  }),
  handleFilterOptions: PropTypes.func,
  filterOptions: PropTypes.array
};

export default LearningPathFilters;
