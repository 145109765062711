import http from 'http/interceptor';
import { getItemFromStore } from 'helpers/utils';
const currentLocale =
  getItemFromStore('preferredLanguage', 'en') ||
  process.env.REACT_APP_DEFAULT_LANGUAGE;
const BASE_API_URL = `${process.env.REACT_APP_API_BASE_URL}/api/${currentLocale}/${process.env.REACT_APP_API_VERSION}`;
const LIST_EXAMS_URL = `${BASE_API_URL}/courses/ListAllExams`;
const GET_EXAM_URL = `${BASE_API_URL}/courses/GetExam`;
const CREATE_EXAM_URL = `${BASE_API_URL}/courses/CreateExam`;
const CREATE_EXAM_QUESTIONS_URL = `${BASE_API_URL}/courses/CreateExamQuestions`;
const LIST_EXAMS_COURSES_URL = `${BASE_API_URL}/courses/listallcourses`;
const DELETE_EXAM_URL = `${BASE_API_URL}/courses/DeleteExam`;

const ExamService = {
  list: data => {
    return http.post(
      LIST_EXAMS_URL,
      { ...data },
      {
        headers: { 'Content-Type': 'Application/Json' }
      }
    );
  },
  get: id => {
    return http.get(`${GET_EXAM_URL}?id=${id}`, {
      headers: { 'Content-Type': 'Application/Json' }
    });
  },
  create: data => {
    return http.post(
      CREATE_EXAM_URL,
      { ...data },
      {
        headers: { 'Content-Type': 'Application/Json' }
      }
    );
  },
  delete: data => {
    return http.post(`${DELETE_EXAM_URL}?id=${data}`, data, {
      headers: { 'Content-Type': 'Application/Json' }
    });
  },
  createQuestions: data => {
    return http.post(
      CREATE_EXAM_QUESTIONS_URL,
      { ...data },
      {
        headers: { 'Content-Type': 'Application/Json' }
      }
    );
  },
  listCourses: data => {
    return http.post(
      LIST_EXAMS_COURSES_URL,
      { ...data },
      {
        headers: { 'Content-Type': 'Application/Json' }
      }
    );
  }
};

export default ExamService;
