import http from 'http/interceptor';
import { objectToQueryString } from './utils';
import { getItemFromStore } from 'helpers/utils';
const currentLocale =
  getItemFromStore('preferredLanguage', 'en') ||
  process.env.REACT_APP_DEFAULT_LANGUAGE;
const BASE_API_URL = `${process.env.REACT_APP_API_BASE_URL}/api/${currentLocale}/${process.env.REACT_APP_API_VERSION}`;
const CREATE_COURSE_URL = `${BASE_API_URL}/courses/create`;
const UPLOAD_COURSE_IMAGE_URL = `${BASE_API_URL}/courses/uploadcourseimage`;
const UPLOAD_COURSE_COVER_URL = `${BASE_API_URL}/courses/uploadcoursecover`;
const UPDATE_COURSE_PROMO_VIDEO = `${BASE_API_URL}/courses/updatecoursepromovideo`;
const UPDATE_USER_PROFILE_URL = `${BASE_API_URL}/account/edit`;
const LIST_COURSES_URL = `${BASE_API_URL}/courses/listallcourses`;
const LIST_CATEGORIES_URL = `${BASE_API_URL}/courses/listallcategories`;
const GET_COURSE_URL = `${BASE_API_URL}/courses/get`;
const UPDATE_COURSE_INFO_URL = `${BASE_API_URL}/courses/updatecourseinfoitem`;
const DELETE_COURSE_INFO_URL = `${BASE_API_URL}/courses/removecourseinfoitem`;
const SAVE_COURSE_CHAPTER_URL = `${BASE_API_URL}/courses/createchapter`;
const UPDATE_COURSE_CHAPTER_URL = `${BASE_API_URL}/courses/chapter/update`;
const DELETE_COURSE_CHAPTER_URL = `${BASE_API_URL}/courses/deletechapter`;
const REORDER_COURSE_CHAPTERS_URL = `${BASE_API_URL}/courses/chapter/order`;
const UPDATE_COURSE_LESSON_URL = `${BASE_API_URL}/courses/lesson/update`;
const CREATE_COURSE_LESSON_URL = `${BASE_API_URL}/courses/createlesson`;
const DELETE_COURSE_LESSON_URL = `${BASE_API_URL}/courses/lesson/delete`;
const UPLOAD_COURSE_LESSON_FILE_URL = `${BASE_API_URL}/courses/lesson/file/upload`;
const UPLOAD_COURSE_LESSON_CONTENT_FILE_URL = `${BASE_API_URL}/courses/lesson/content/upload`;
const UPDATE_COURSE_LESSON_CONTENT_URL = `${BASE_API_URL}/courses/lesson/content/update`;
const GET_COURSE_LESSON_URL = `${BASE_API_URL}/courses/lesson/get`;
const REORDER_COURSE_CHAPTER_LESSONS_URL = `${BASE_API_URL}/courses/lesson/order`;
const CREATE_COURSE_QUIZ_URL = `${BASE_API_URL}/courses/quiz/create`;
const CREATE_COURSE_PRACTICECODE_URL = `${BASE_API_URL}/courses/practiceCode/create`;
const DELETE_COURSE_CHAPTER_QUIZ_URL = `${BASE_API_URL}/courses/quiz/delete`;
const DELETE_COURSE_CHAPTER_PRACTICECODE_URL = `${BASE_API_URL}/courses/practiceCode/delete`;
const LIST_INSTRUCTORS_URL = `${BASE_API_URL}/courses/instructor/list`;
const SAVE_INSTRUCTOR_URL = `${BASE_API_URL}/courses/instructor/create`;
const SAVE_COURSE_INSTRUCTOR_URL = `${BASE_API_URL}/courses/instructor/add`;
const SAVE_COURSE_LESSON_RESOURCES_URL = `${BASE_API_URL}/courses/lesson/resources/save`;
const DELETE_COURSE_LESSON_RESOURCE_URL = `${BASE_API_URL}/courses/lesson/resources/delete`;
const SAVE_COURSE_PRICE_URL = `${BASE_API_URL}/courses/UpdateCoursePrice`;
const GET_COURSE_AUDIT_EVENTS_URL = `${BASE_API_URL}/courses/ListAuditEvants`;
const GET_COURSE_AVAILABLE_TRANSITIONS_URL = `${BASE_API_URL}/courses/ListCourseTransitions`;
const COURSE_DO_TRANSITION_URL = `${BASE_API_URL}/courses/UpdateCourseTransition`;

const CourseService = {
  create: data => {
    return http.post(
      CREATE_COURSE_URL,
      { ...data },
      {
        headers: { 'Content-Type': 'Application/Json' }
      }
    );
  },
  update: data => {
    return http.post(
      UPDATE_USER_PROFILE_URL,
      { ...data },
      {
        headers: { 'Content-Type': 'Application/Json' }
      }
    );
  },
  list: filters => {
    const url = LIST_COURSES_URL;
    return http.post(url, filters, {
      headers: { 'Content-Type': 'Application/Json' }
    });
  },
  listCategories: filters => {
    const url = LIST_CATEGORIES_URL;
    return http.post(url, filters, {
      headers: { 'Content-Type': 'Application/Json' }
    });
  },
  get: id => {
    const url = `${GET_COURSE_URL}?id=${id}`;

    return http.get(url, {
      headers: { 'Content-Type': 'Application/Json' }
    });
  },
  uploadCourseImage: data => {
    const url = `${UPLOAD_COURSE_IMAGE_URL}`;
    return http.post(url, data, {
      headers: { 'Content-Type': 'multipart/form-data' }
    });
  },
  uploadCourseCover: data => {
    const url = `${UPLOAD_COURSE_COVER_URL}`;
    return http.post(url, data, {
      headers: { 'Content-Type': 'multipart/form-data' }
    });
  },
  updateCoursePromoVideo: data => {
    const url = `${UPDATE_COURSE_PROMO_VIDEO}`;
    return http.post(url, data, {
      headers: { 'Content-Type': 'Application/Json' }
    });
  },
  updateInfo: data => {
    const url = `${UPDATE_COURSE_INFO_URL}`;

    return http.post(url, data, {
      headers: { 'Content-Type': 'Application/Json' }
    });
  },
  deleteInfoItem: data => {
    const url = `${DELETE_COURSE_INFO_URL}`;

    return http.post(url, data, {
      headers: { 'Content-Type': 'Application/Json' }
    });
  },
  saveChapter: data => {
    const url = `${SAVE_COURSE_CHAPTER_URL}`;
    return http.post(url, data, {
      headers: { 'Content-Type': 'Application/Json' }
    });
  },
  updateChapter: data => {
    const url = `${UPDATE_COURSE_CHAPTER_URL}`;
    return http.post(url, data, {
      headers: { 'Content-Type': 'Application/Json' }
    });
  },
  reorderChapters: data => {
    const url = `${REORDER_COURSE_CHAPTERS_URL}`;
    return http.post(url, data, {
      headers: { 'Content-Type': 'Application/Json' }
    });
  },
  deleteChapter: data => {
    const url = `${DELETE_COURSE_CHAPTER_URL}`;
    return http.post(url, data, {
      headers: { 'Content-Type': 'Application/Json' }
    });
  },
  updateLesson: data => {
    const url = `${UPDATE_COURSE_LESSON_URL}`;
    return http.post(url, data, {
      headers: { 'Content-Type': 'Application/Json' }
    });
  },
  createLesson: data => {
    const url = `${CREATE_COURSE_LESSON_URL}`;
    return http.post(url, data, {
      headers: { 'Content-Type': 'Application/Json' }
    });
  },
  deleteLesson: data => {
    const url = `${DELETE_COURSE_LESSON_URL}`;
    return http.post(url, data, {
      headers: { 'Content-Type': 'Application/Json' }
    });
  },
  uploadLessonFile: data => {
    const url = `${UPLOAD_COURSE_LESSON_FILE_URL}`;
    return http.post(url, data, {
      headers: { 'Content-Type': 'Application/Json' }
    });
  },
  uploadLessonContentFile: data => {
    const url = `${UPLOAD_COURSE_LESSON_CONTENT_FILE_URL}`;
    return http.post(url, data, {
      headers: { 'Content-Type': 'Application/Json' }
    });
  },
  updateLessonContent: data => {
    const url = `${UPDATE_COURSE_LESSON_CONTENT_URL}`;
    return http.post(url, data, {
      headers: { 'Content-Type': 'Application/Json' }
    });
  },
  getLesson: id => {
    const url = `${GET_COURSE_LESSON_URL}?id=${id}`;
    return http.get(url, {
      headers: { 'Content-Type': 'Application/Json' }
    });
  },
  reorderLessons: data => {
    const url = `${REORDER_COURSE_CHAPTER_LESSONS_URL}`;
    return http.post(url, data, {
      headers: { 'Content-Type': 'Application/Json' }
    });
  },
  createQuiz: data => {
    const url = `${CREATE_COURSE_QUIZ_URL}`;
    return http.post(url, data, {
      headers: { 'Content-Type': 'Application/Json' }
    });
  },
  createPracticeCode: data => {
    const url = `${CREATE_COURSE_PRACTICECODE_URL}`;
    return http.post(url, data, {
      headers: { 'Content-Type': 'Application/Json' }
    });
  },
  deleteQuiz: data => {
    const url = `${DELETE_COURSE_CHAPTER_QUIZ_URL}`;
    return http.post(url, data, {
      headers: { 'Content-Type': 'Application/Json' }
    });
  },
  listInstrutors: data => {
    const url = `${LIST_INSTRUCTORS_URL}?${objectToQueryString(data)}`;
    return http.get(url, {
      headers: { 'Content-Type': 'Application/Json' }
    });
  },
  saveInstructor: data => {
    const url = `${SAVE_INSTRUCTOR_URL}`;
    return http.post(url, data, {
      headers: { 'Content-Type': 'Application/Json' }
    });
  },
  saveCourseInstructors: data => {
    const url = `${SAVE_COURSE_INSTRUCTOR_URL}`;
    return http.post(url, data, {
      headers: { 'Content-Type': 'Application/Json' }
    });
  },
  saveLessonResources: data => {
    const url = `${SAVE_COURSE_LESSON_RESOURCES_URL}`;
    return http.post(url, data, {
      headers: { 'Content-Type': 'Application/Json' }
    });
  },
  deleteLessonResource: data => {
    const url = `${DELETE_COURSE_LESSON_RESOURCE_URL}`;
    return http.post(url, data, {
      headers: { 'Content-Type': 'Application/Json' }
    });
  },
  saveCoursePrice: data => {
    const url = `${SAVE_COURSE_PRICE_URL}`;
    return http.post(url, data, {
      headers: { 'Content-Type': 'Application/Json' }
    });
  },
  getCourseAuditEvents: id => {
    const url = `${GET_COURSE_AUDIT_EVENTS_URL}?id=${id}`;
    return http.get(url, {
      headers: { 'Content-Type': 'Application/Json' }
    });
  },
  getCourseAvailableTransitions: id => {
    const url = `${GET_COURSE_AVAILABLE_TRANSITIONS_URL}?id=${id}`;
    return http.get(url, {
      headers: { 'Content-Type': 'Application/Json' }
    });
  },
  doTransition: data => {
    const url = `${COURSE_DO_TRANSITION_URL}`;
    return http.post(url, data, {
      headers: { 'Content-Type': 'Application/Json' }
    });
  },
  deletePracticeCode: data => {
    const url = `${DELETE_COURSE_CHAPTER_PRACTICECODE_URL}`;
    return http.post(url, data, {
      headers: { 'Content-Type': 'Application/Json' }
    });
  }
};

export default CourseService;
